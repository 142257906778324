import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { serverUrl } from '../../scripts/config';
import styles from './Login.module.css';

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [messageVisible, setMessageVisible] = useState(false);
    const navigate = useNavigate();

    const togglePasswordVisibility = () => {
        const passwordInput = document.getElementById('password');
        const toggleButton = document.getElementById('togglePassword');
        if (passwordInput.type === 'password') {
            passwordInput.type = 'text';
            toggleButton.classList.add(styles.active);
        } else {
            passwordInput.type = 'password';
            toggleButton.classList.remove(styles.active);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await fetch(`${serverUrl}/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email, password })
            });

            const result = await response.json();

            if (response.ok) {
                localStorage.setItem('token', result.token);
                setMessage('Login realizado com sucesso!');
                setMessageVisible(true);
                navigate('/');
            } else {
                setMessage(result.message || 'Erro ao realizar login.');
                setMessageVisible(true);
            }
        } catch (error) {
            console.error('Erro ao fazer login:', error);
            setMessage('Erro ao realizar login. Tente novamente.');
            setMessageVisible(true);
        }
    };

    return (
        <div className={styles.loginPage}>
            <Helmet>
                <title>Login - SparkFluxe | ADM</title>
            </Helmet>

            <div className={styles.loginContainer}>
                <h2>SparkFluxe ADM</h2>
                <form onSubmit={handleSubmit}>
                    <div className={`form-group`}>
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            className={`form-control ${styles.formControl}`}
                            id="email"
                            placeholder="Escreva seu e-mail"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className={`form-group ${styles.passwordToggle}`}>
                        <label htmlFor="password">Senha</label>
                        <div style={{ position: 'relative' }}>
                            <input
                                type="password"
                                className={`form-control ${styles.formControl}`}
                                id="password"
                                placeholder="Escreva sua senha"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                            <button type="button" id="togglePassword" onClick={togglePasswordVisibility} aria-label="Toggle password visibility">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <path d="M12 3.75C7.15 3.75 3 9 3 9s4.15 5.25 9 5.25s9-5.25 9-5.25s-4.15-5.25-9-5.25zm0 8.25c-1.34 0-2.46-.82-3.07-2h6.14c-.61 1.18-1.73 2-3.07 2zm0-6c-.78 0-1.5.22-2.12.59c.18.33 .34 .68 .48 1.03c.29-.19 .6-.35 .92-.48c.37-.15 .77-.24 1.19-.24c1.54 0 2.84 1.03 3.29 2.45c.14.36 .22 .74 .22 1.14s-.08 .78-.22 1.14c-.45 1.42-1.75 2.45-3.29 2.45c-.42 0-.82-.09-1.19-.24c-.32-.13-.63-.29-.92-.48c-.15.35-.31 .7-.48 1.03c.62.37 1.34.59 2.12.59c2.21 0 4-1.79 4-4s-1.79-4-4-4z" />
                                </svg>
                            </button>
                        </div>
                    </div>
                    <button type="submit" className={`btn btn-custom btn-block ${styles.btnCustom}`}>Login</button>
                    <div id="message" className={`alert ${styles.alert} ${message ? (message.includes('sucesso') ? 'alert-success' : 'alert-danger') : ''}`} style={{ display: messageVisible ? 'block' : 'none' }}>
                        {message}
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Login;
