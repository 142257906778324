import React from 'react';
import Chat from './components/Chat';
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import Footer from './components/Footer';
import { Container, Row, Col } from 'react-bootstrap';
import './ChatgptPage.css';

const App = () => {
  const handleNewChat = () => {
    alert('Novo chat iniciado!');
  };

  const handleSendFile = (file) => {
    alert(`Arquivo enviado: ${file.name}`);
  };

  return (
    <div className="app-container">
      <div className="app">
        <Header version="GPT-4" />
        <Container fluid className="main-content">
          <Row noGutters className="flex-nowrap" style={{ height: 'calc(100vh - 112px)' }}>
            <Col xs={3} className="sidebar-column">
              <Sidebar onNewChat={handleNewChat} onSendFile={handleSendFile} />
            </Col>
            <Col xs={9} className="chat-column">
              <Chat />
            </Col>
          </Row>
        </Container>
        <Footer />
      </div>
    </div>
  );
};

export default App;
