import React from 'react';
import Navbar from 'react-bootstrap/Navbar';

const Footer = () => (
  <Navbar bg="dark" variant="dark" fixed="bottom">
    <Navbar.Text className="m-auto">ChatGPT Interface © 2024</Navbar.Text>
  </Navbar>
);

export default Footer;
