import React, { useState, useEffect } from 'react';
import Navbar from '../../Common/Navbar';
import DynamicTable from '../../Common/table/DynamicTable';
import { Container, Button, FormControl, InputGroup } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import { getProducts, createProduct, updateProduct, deleteProduct } from '../../services/productService';
import { getCategories } from '../../services/categoryService';
import { updateProductImage } from '../../services/imageService';
import { useNavigate } from 'react-router-dom'; // Importar useNavigate do react-router-dom
import CategoriesContainer from './Componentes/CategoriasContainer';
import ImageModal from './../../Common/modal-envio-de-arquivos/ImageModal';
import Select from 'react-select';
import { serverUrl } from '../../services/api';
import { getAllEstoques } from '../../services/estoqueService'; // Importar o serviço de estoques
import './Produtos.css';
import '../../Common/styles/botoes.css'

// Função para integrar produtos com estoques
const integrarProdutosComEstoques = (produtos, estoques) => {
  return produtos.map(produto => {
    const estoque = estoques.find(e => e.produtoId === produto.id);
    return {
      ...produto,
      estoque: estoque ? estoque.estoqueAtual : 0
    };
  });
};

function ProductsPage() {
  const navigate = useNavigate(); // Inicializar useNavigate

  const [showImageModal, setShowImageModal] = useState(false);
  const [currentProductId, setCurrentProductId] = useState(null);

  const handleImageButtonClick = (productId) => {
    setCurrentProductId(productId);
    setShowImageModal(true);
  };

  const handleImageUpload = async (file, productId) => {
    if (file) {
      const formData = new FormData();
      formData.append('imagem', file);

      try {
        const updatedProduct = await updateProductImage(productId, formData, 'Produto');

        // Atualize o estado dos produtos para renderizar a nova imagem imediatamente
        setProducts(prevProducts =>
          prevProducts.map(product =>
            product.id === productId ? { ...product, imagem: updatedProduct.imagem } : product
          )
        );
        setFilteredProducts(prevFilteredProducts =>
          prevFilteredProducts.map(product =>
            product.id === productId ? { ...product, imagem: updatedProduct.imagem } : product
          )
        );

        // Forçar recarregamento da página após a atualização
        window.location.reload();
      } catch (error) {
        console.error("Erro ao atualizar imagem do produto:", error);
      }
    }
  };

  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [categories, setCategories] = useState([]);
  const [filters, setFilters] = useState({
    productName: '',
    category: ''
  });

  useEffect(() => {
    const fetchProductsAndEstoques = async () => {
      try {
        const [productsData, estoquesData] = await Promise.all([getProducts(), getAllEstoques()]);
        const updatedProducts = integrarProdutosComEstoques(productsData, estoquesData);
        setProducts(updatedProducts);
        setFilteredProducts(updatedProducts);
      } catch (error) {
        console.error("Erro ao buscar produtos e estoques:", error);
      }
    };

    fetchProductsAndEstoques();
  }, []);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const data = await getCategories();
        setCategories(data);
      } catch (error) {
        console.error("Erro ao buscar categorias:", error);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    let updatedProducts = products;
    if (filters.productName !== '') {
      updatedProducts = updatedProducts.filter(product =>
        product.nome.toLowerCase().includes(filters.productName.toLowerCase())
      );
    }
    if (filters.category !== '') {
      updatedProducts = updatedProducts.filter(product =>
        product.categoria.toLowerCase().includes(filters.category.toLowerCase())
      );
    }
    setFilteredProducts(updatedProducts);
  }, [filters, products]);

  const categoryOptions = categories.map(category => ({
    value: category.name,
    label: category.name
  }));

  const handleAddProduct = async () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.multiple = true;
    input.onchange = async (e) => {
      try {
        await createProduct(e.target.files);
        const data = await getProducts();
        setProducts(data);
        setFilteredProducts(data);
      } catch (error) {
        console.error("Erro ao adicionar produto:", error);
      }
    };
    input.click();
  };

  const handleEditProduct = async (id, updatedFields) => {
    try {
      const updatedProduct = await updateProduct(id, updatedFields);
      setProducts(products.map(p => (p.id === id ? updatedProduct : p)));
      setFilteredProducts(filteredProducts.map(p => (p.id === id ? updatedProduct : p)));
    } catch (error) {
      console.error(`Erro ao editar produto ${id}:`, error);
    }
  };

  const handleDeleteProduct = async (id) => {
    try {
      await deleteProduct(id);
      setProducts(products.filter(p => p.id !== id));
      setFilteredProducts(filteredProducts.filter(p => p.id !== id));
    } catch (error) {
      console.error(`Erro ao deletar produto ${id}:`, error);
    }
  };

  const columns = [
    {
      key: 'imagem',
      label: 'Imagem',
      editable: false,
      width: '8%',
      render: (_, row) => (
        row.imagem ? (
          <img
            src={`${serverUrl}/api${row.imagem}`}
            alt="Imagem do Produto"
            className="cadastro_produtos product-image"
            onClick={() => handleImageButtonClick(row.id)}
          />
        ) : (
          <Button variant="outline-primary" size="sm" onClick={() => handleImageButtonClick(row.id)}>
            Enviar Imagens
          </Button>
        )
      )
    },
    { key: 'id', label: 'Id', editable: false, width: '5%' },
    { key: 'estoque', label: 'Estoque', width: '5%', editable: false }, // Adicionada a coluna de estoque
    { key: 'nome', label: 'Produto', width: '20%' },
    {
      key: 'valor',
      label: 'Valor',
      type: 'number',
      width: '10%',
      render: (value) => {
        const numValue = parseFloat(value);
        return !isNaN(numValue) ? `R$ ${numValue.toFixed(2)}` : 'N/A';
      }
    },
    { key: 'especificacoes', label: 'Especificações', type: 'textarea', width: '20%' },
    { key: 'categoria', label: 'Categoria', type: 'select', width: '15%', options: categoryOptions }
  ];

  const handleFilterChange = (selectedOption, action) => {
    setFilters({
      ...filters,
      [action.name]: selectedOption ? selectedOption.value : ''
    });
  };

  return (
    <div className="cadastro_produtos products-page">
      <Navbar />
      <Container>
        <header className="cadastro_produtos page-header">
          <h1 className="cadastro_produtos title">Cadastro e visualização de produtos</h1>
          <div>
            <Button bsPrefix="btn-primary-custom" onClick={handleAddProduct}>
              <FaPlus /> Adicionar Produto
            </Button>
            <Button bsPrefix="btn-primary-custom" onClick={() => setShowModal(true)}>
              <FaPlus /> Adicionar Categoria
            </Button>
            <Button variant="danger" onClick={() => navigate(-1)} bsPrefix="btn-danger-custom">
              Voltar
            </Button>
          </div>
        </header>
        <section className="cadastro_produtos filter-section">
          <InputGroup className="mb-3" style={{ alignItems: 'center' }}>
            <FormControl
              placeholder="Nome do Produto"
              name="productName"
              value={filters.productName}
              onChange={e => handleFilterChange({ value: e.target.value }, { name: 'productName' })}
              className="cadastro_produtos filter-input"
            />
            <Select
              name="category"
              value={categoryOptions.find(option => option.value === filters.category)}
              onChange={handleFilterChange}
              options={[{ value: '', label: 'Todas as Categorias' }, ...categoryOptions]}
              className="cadastro_produtos category-dropdown"
              classNamePrefix="cadastro_produtos select"
              placeholder="Todas as Categorias"
              isClearable
            />
          </InputGroup>
        </section>
        <section className="cadastro_produtos products-section">
          <DynamicTable
            columns={columns}
            data={filteredProducts}
            onEdit={handleEditProduct}
            onDelete={handleDeleteProduct}
            categories={categories} // Passando categorias para DynamicTable
          />
        </section>
      </Container>

      <CategoriesContainer show={showModal} handleClose={() => setShowModal(false)} />

      <ImageModal
        show={showImageModal}
        onHide={() => setShowImageModal(false)}
        onUpload={handleImageUpload}
        produtoId={currentProductId}
        serverUrl={serverUrl}
      />
    </div>
  );
}

export default ProductsPage;
