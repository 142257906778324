import React from 'react';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';

const Sidebar = ({ onNewChat, onSendFile }) => (
  <div className="sidebar">
    <Button onClick={onNewChat} variant="outline-primary" className="mb-3">Novo Chat</Button>
    <Button onClick={() => document.getElementById('fileInput').click()} variant="outline-secondary">Enviar Arquivo</Button>
    <input
      type="file"
      id="fileInput"
      style={{ display: 'none' }}
      onChange={(e) => onSendFile(e.target.files[0])}
    />
    <ListGroup className="mt-3">
      <ListGroup.Item>Chat 1</ListGroup.Item>
      <ListGroup.Item>Chat 2</ListGroup.Item>
      <ListGroup.Item>Chat 3</ListGroup.Item>
    </ListGroup>
  </div>
);

export default Sidebar;
