import api from './api';

export const getCategories = async () => {
  const response = await api.get('/categories');
  if (response.status === 200) {
    return response.data;
  }
  throw new Error('Erro ao buscar categorias');
};

export const addCategory = async (name) => {
  const response = await api.post('/categories', { name });
  if (response.status === 201) {
    return response.data;
  }
  throw new Error('Erro ao adicionar categoria');
};

export const updateCategory = async (id, data) => {
  const response = await api.put(`/categories/${id}`, data);
  if (response.status === 200) {
    return response.data;
  }
  throw new Error('Erro ao editar categoria');
};

export const deleteCategory = async (id) => {
  const response = await api.delete(`/categories/${id}`);
  if (response.status === 204) {
    return;
  }
  throw new Error('Erro ao remover categoria');
};
