import React from 'react';
import './styles/Sidebar.css';

function Sidebar() {
    return (
        <div className="sidebar">
            <a href="/" className="active"><i className="fas fa-home"></i> Página Inicial</a>
            <a href="/produtos-page"><i className="fas fa-box"></i> Produtos</a>
            <a href="/financeiro"><i className="fas fa-chart-line"></i> Financeiro</a>
            <a href="/chatbot"><i className="fas fa-users"></i> ChatBot</a>
            <a href="/aplicativos"><i className="fas fa-shopping-cart"></i> Aplicativos</a>
            <a href="/configuracoes"><i className="fas fa-cogs"></i> Configurações</a>
        </div>
    );
}

export default Sidebar;
