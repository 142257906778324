import React from 'react';
import ConfigCard from '../../Common/cards/ConfigCard';

const ControlePage = () => {
    const cards = [
        {
            title: 'Registro de Produtos',
            description: 'Editar as mensagens padrões do ChatBOT, permitindo uma melhor personalização.',
            route: 'cadastro_produtos',  // Este cartão redireciona para outra página
        },
        {
            title: 'Entradas',
            description: 'Gerenciar as cidades onde o serviço está disponível.',
            route: 'entradas',  // Este cartão redireciona para outra página
        },
        {
            title: 'Saídas',
            description: 'Configurar o WhatsApp para integração com o sistema.',
            route: 'saidas',  // Este cartão redireciona para outra página
        },
    ];

    return (
        <div>
            <ConfigCard cards={cards} />
        </div>
    );
};

export default ControlePage;
