import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Button } from 'react-bootstrap';
import { serverUrl } from '../../../../services/api';
import { updateAnuncio } from '../../../../services/anuncioService'; // Certifique-se de que o caminho está correto
import CityModal from '../CityModal/CityModal';
import cityService from '../../../../services/configuracoes_do_sistema/cityService';
import './DynamicCards.css';

const DynamicCards = ({ cardsData, handleCardClick }) => {
    const [showModal, setShowModal] = useState(false);
    const [selectedCard, setSelectedCard] = useState(null);
    const [cities, setCities] = useState({});
    const [activatedCities, setActivatedCities] = useState({});
    const [allCities, setAllCities] = useState([]);

    useEffect(() => {
        const fetchCities = async () => {
            const citiesData = await cityService.getCities();
            setAllCities(citiesData);

            const initialActivatedCities = {};
            cardsData.forEach(card => {
                if (card.cidade) {
                    const activeCities = card.cidade.split(',').map(cep => cep.trim());
                    initialActivatedCities[card.id] = citiesData
                        .filter(city => activeCities.includes(city.cep))
                        .map(city => city.id);
                }
            });
            setActivatedCities(initialActivatedCities);
        };
        fetchCities();
    }, [cardsData]); // Certifique-se de que esta dependência não cause loop infinito

    const handleCardClickInternal = (card) => {
        handleCardClick(card);
    };

    const handleButtonClick = (e, card) => {
        e.stopPropagation();  // Para impedir que o clique no botão também acione o clique no card
        setSelectedCard(card);
        setShowModal(true);
    };

    const handleCitiesChange = (cardId, updatedCities) => {
        setCities((prevCities) => ({
            ...prevCities,
            [cardId]: updatedCities,
        }));
    };

    const handleActivatedCitiesChange = (cardId, updatedActivatedCities) => {
        setActivatedCities((prevActivatedCities) => ({
            ...prevActivatedCities,
            [cardId]: updatedActivatedCities,
        }));
    };

    const getButtonStyle = (cardId) => {
        const activeCitiesCount = activatedCities[cardId]?.length || 0;
        const totalCitiesCount = allCities.length;
        const percentage = (activeCitiesCount / totalCitiesCount) * 100;

        return {
            background: `linear-gradient(to right, #28a745 ${percentage}%, #ccc ${percentage}%)`,
            color: percentage === 100 ? 'white' : 'black',
            borderRadius: '50px',
            padding: '10px 20px',
            fontSize: '16px',
            fontWeight: 'bold',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            transition: 'background-color 0.3s ease, box-shadow 0.3s ease'
        };
    };

    const getButtonText = (cardId) => {
        const activeCitiesCount = activatedCities[cardId]?.length || 0;
        const totalCitiesCount = allCities.length;
        return activeCitiesCount === totalCitiesCount ? 'Ativado' : 'Ativar';
    };

    function statusFormatado(status) {
        if (status === 'Ativo') {
            return { texto: status, cor: 'green' };
        } else if (status === 'Inativo') {
            return { texto: status, cor: 'red' };
        } else {
            return { texto: status, cor: 'black' };
        }
    }

    function visualizacoesFormatado(visualizacoes) {
        return { texto: visualizacoes, cor: 'blue', fontWeight: 'bold' }; // Ajuste a cor conforme necessário
    }

    function posicaoFormatado(posicao) {
        return { texto: posicao, cor: 'purple', fontWeight: 'bold' }; // Ajuste a cor conforme necessário
    }

    return (
        <>
            <Row>
                {cardsData.map(card => (
                    <Col key={card.id} xs={6} sm={4} md={3}>
                        <Card className="dynamic-card" onClick={() => handleCardClickInternal(card)}>
                            {card.Produto && card.Produto.imagem && (
                                <Card.Img variant="top" src={`${serverUrl}/api${card.Produto.imagem}`} />
                            )}
                            <Card.Body>
                                <Card.Title>{card.Produto ? card.Produto.nome : 'Nome do Produto'}</Card.Title>
                                <Card.Text className="card-details">
                                    <div className="detail-item">
                                        <strong>Status:</strong>
                                        <span style={{ color: statusFormatado(card.status).cor, fontWeight: 'bold' }}>
                                            {statusFormatado(card.status).texto}
                                        </span>
                                    </div>
                                    <div className="detail-item">
                                        <strong>Vis.:</strong>
                                        <span>
                                            <span style={{ color: visualizacoesFormatado(card.visualizacoes_do_anuncio).cor, fontWeight: visualizacoesFormatado(100).fontWeight }}>
                                                {visualizacoesFormatado(card.visualizacoes_do_anuncio).texto}
                                            </span>
                                            {' visualizações'}
                                        </span>
                                    </div>
                                    <div className="detail-item">
                                        <strong>Pos.:</strong>
                                        <span>
                                            <span style={{ color: posicaoFormatado(card.posicao_do_anuncio).cor, fontWeight: posicaoFormatado(5).fontWeight }}>
                                                {posicaoFormatado(card.posicao_do_anuncio).texto}
                                            </span>
                                            {'º posição'}
                                        </span>
                                    </div>
                                </Card.Text>
                                <div className="card-buttons">
                                    <Button
                                        style={getButtonStyle(card.id)}
                                        className="beautiful-button"
                                        onClick={(e) => handleButtonClick(e, card)}>
                                        {getButtonText(card.id)}
                                    </Button>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
            {selectedCard && (
                <CityModal
                    show={showModal}
                    onHide={() => setShowModal(false)}
                    selectedCard={selectedCard}
                    updateAnuncio={updateAnuncio}
                    handleCitiesChange={handleCitiesChange}
                    handleActivatedCitiesChange={handleActivatedCitiesChange}
                    cities={cities[selectedCard.id] || []}
                    activatedCities={activatedCities[selectedCard.id] || []}
                    allCities={allCities}
                />
            )}
        </>
    );
};

export default DynamicCards;
