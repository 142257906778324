import React, { useState, useEffect } from 'react';
import Navbar from '../../Common/Navbar';
import { Container, Button, FormControl, InputGroup } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import axios from 'axios';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import DynamicCards from './componentes/DynamicCards/DynamicCards';
import AnuncioForm from './componentes/AnuncioForm/AnuncioForm';
import CategoriesContainer from './componentes/categorias_container/CategoriasContainerOLX';
import { serverUrl } from '../../services/api';
import './PostagemOLX.css';
import '../../Common/styles/botoes.css'

const statusOptions = [
  { value: 'ativo', label: 'Ativo' },
  { value: 'inativo', label: 'Inativo' },
];

const sortOptions = [
  { value: 'visualizacoes_do_anuncio', label: 'Visualizações' },
  { value: 'posicao_do_anuncio', label: 'Posição' },
];

function PostagemOLX() {
  const navigate = useNavigate();

  const [showCategoriesModal, setShowCategoriesModal] = useState(false);
  const handleOpenCategoriesModal = () => setShowCategoriesModal(true);
  const handleCloseCategoriesModal = () => setShowCategoriesModal(false);

  const [showModal, setShowModal] = useState(false);
  const [currentAnuncioId, setCurrentAnuncioId] = useState(null);
  const [anuncios, setAnuncios] = useState([]);
  const [filteredAnuncios, setFilteredAnuncios] = useState([]);
  const [filters, setFilters] = useState({
    productName: '',
    category: '',
    status: '',
    sortBy: sortOptions[0].value,
  });

  const [newAnuncio, setNewAnuncio] = useState({
    titulo: '',
    descricao: '',
    categoria: '',
    subcategoria: '',
    tipo: '',
    condicao: '',
    cidade: '',
    imagens: [],
    Produto: { nome: '', imagem: '' }
  });

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchAnuncios = async () => {
      try {
        const response = await axios.get(`${serverUrl}/api/anuncios`);
        setAnuncios(response.data);
        setFilteredAnuncios(response.data);
      } catch (error) {
        console.error('Erro ao buscar anúncios:', error);
      }
    };

    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${serverUrl}/api/categories`);
        setCategories(response.data);
      } catch (error) {
        console.error('Erro ao buscar categorias:', error);
      }
    };

    fetchAnuncios();
    fetchCategories();
  }, []);

  useEffect(() => {
    let updatedAnuncios = anuncios;
    if (filters.productName !== '') {
      updatedAnuncios = updatedAnuncios.filter(anuncio =>
        anuncio.Produto.nome.toLowerCase().includes(filters.productName.toLowerCase())
      );
    }
    if (filters.category !== '') {
      updatedAnuncios = updatedAnuncios.filter(anuncio =>
        anuncio.Produto.categoria && anuncio.Produto.categoria.toLowerCase() === filters.category.toLowerCase()
      );
    }
    if (filters.status !== '') {
      updatedAnuncios = updatedAnuncios.filter(anuncio =>
        anuncio.status && anuncio.status.toLowerCase() === filters.status.toLowerCase()
      );
    }
    updatedAnuncios = updatedAnuncios.sort((a, b) => b[filters.sortBy] - a[filters.sortBy]);
    setFilteredAnuncios(updatedAnuncios);
  }, [filters, anuncios]);

  const categoryOptions = categories.map(category => ({
    value: category.name,
    label: category.name
  }));

  const handleFilterChange = (selectedOption, action) => {
    setFilters({
      ...filters,
      [action.name]: selectedOption ? selectedOption.value : ''
    });
  };

  const handleCardClick = (anuncio) => {
    setCurrentAnuncioId(anuncio.id);
    setNewAnuncio({
      id: anuncio.id,
      titulo: anuncio.titulo || '',
      descricao: anuncio.descricao || '',
      produtosId: anuncio.produtosId || '',
      categoria: anuncio.categoria || '',
      subcategoria: anuncio.subcategoria || '',
      tipo: anuncio.tipo || '',
      condicao: anuncio.condicao || '',
      cidade: anuncio.cidade || '',
      imagens: anuncio.imagens.map(img => ({ file: img, preview: `${serverUrl}/api${img}` })) || [],
      Produto: anuncio.Produto || { nome: '', imagem: '' }
    });
    setShowModal(true);
  };

  const handleSaveAnuncio = async (anuncio) => {
    try {
      const response = anuncio.id
        ? await axios.put(`${serverUrl}/api/anuncios/${anuncio.id}`, anuncio)
        : await axios.post(`${serverUrl}/api/anuncios`, anuncio);

      const updatedAnuncio = response.data;

      const updatedProduto = anuncio.Produto
        ? { ...anuncio.Produto, imagem: anuncio.Produto.imagem }
        : { nome: '', imagem: '' };

      setAnuncios(anuncios.map(a => (a.id === updatedAnuncio.id ? { ...updatedAnuncio, Produto: updatedProduto } : a)));
      setFilteredAnuncios(filteredAnuncios.map(a => (a.id === updatedAnuncio.id ? { ...updatedAnuncio, Produto: updatedProduto } : a)));

      setNewAnuncio(prevState => ({
        ...prevState,
        ...updatedAnuncio,
        Produto: updatedProduto
      }));

      setShowModal(false);
    } catch (error) {
      console.error('Erro ao salvar anúncio:', error);
    }
  };

  return (
    <div className="postagem_olx-page">
      <Navbar />
      <Container>
        <header className="postagem_olx-page-header">
          <h1 className="postagem_olx-title">Gerenciamento de Anúncios OLX</h1>
          <div>
            <Button bsPrefix="btn-primary-custom" onClick={handleOpenCategoriesModal}>
              <FaPlus /> Adicionar categoria
            </Button>
            <Button variant="danger" onClick={() => navigate(-1)} bsPrefix="btn-danger-custom">
              Voltar
            </Button>
            <CategoriesContainer
              show={showCategoriesModal}
              handleClose={handleCloseCategoriesModal}
            />
          </div>
        </header>
        <section className="postagem_olx-filter-section">
          <div className="postagem_olx-filter-row">
            <div className="postagem_olx-filter-item">
              <FormControl
                placeholder="Nome do Produto"
                name="productName"
                value={filters.productName}
                onChange={e => handleFilterChange({ value: e.target.value }, { name: 'productName' })}
                className="postagem_olx-filter-input"
              />
            </div>
            <div className="postagem_olx-filter-item">
              <Select
                name="category"
                value={categoryOptions.find(option => option.value === filters.category)}
                onChange={handleFilterChange}
                options={[{ value: '', label: 'Todas as Categorias' }, ...categoryOptions]}
                className="postagem_olx-react-select-container"
                classNamePrefix="postagem_olx-select"
                placeholder="Todas as Categorias"
                isClearable
              />
            </div>
          </div>
          <div className="postagem_olx-filter-row">
            <div className="postagem_olx-filter-item">
              <Select
                name="status"
                value={statusOptions.find(option => option.value === filters.status)}
                onChange={handleFilterChange}
                options={statusOptions}
                className="postagem_olx-react-select-container"
                classNamePrefix="postagem_olx-select"
                placeholder="Status do Anúncio"
                isClearable
              />
            </div>
            <div className="postagem_olx-filter-item">
              <Select
                name="sortBy"
                value={sortOptions.find(option => option.value === filters.sortBy)}
                onChange={handleFilterChange}
                options={sortOptions}
                className="postagem_olx-react-select-container"
                classNamePrefix="postagem_olx-select"
                placeholder="Visualizações"
              />
            </div>
          </div>
        </section>
        <section className="postagem_olx-products-section">
          <DynamicCards
            cardsData={filteredAnuncios}
            handleCardClick={handleCardClick}
          />
        </section>

        <AnuncioForm
          show={showModal}
          onHide={() => setShowModal(false)}
          anuncio={newAnuncio}
          setAnuncio={setNewAnuncio}
          handleSave={handleSaveAnuncio}
        />
      </Container>
    </div>
  );
}

export default PostagemOLX;
