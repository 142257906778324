import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from '../../Common/Navbar';
import DynamicTable from '../../Common/table/DynamicTable';
import { Button, Form, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { getEntradasEstoque, createEntradaEstoque, updateEntradaEstoque, deleteEntradaEstoque } from '../../services/estoqueEntradasService';
import { getProducts } from '../../services/productService';
import fornecedorService from '../../services/configuracoes_do_sistema/fornecedorService';
import FormModal from './components/FormModal';
import './Controle_de_Estoque_Entradas.css';

function ControleDeEstoqueEntradas() {
    const navigate = useNavigate();

    const [showModal, setShowModal] = useState(false);
    const [entries, setEntries] = useState([]);
    const [products, setProducts] = useState([]);
    const [fornecedores, setFornecedores] = useState([]);
    const [filteredEntries, setFilteredEntries] = useState([]);
    const [filter, setFilter] = useState({
        productName: '',
        startDate: '',
        endDate: ''
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const entradaData = await getEntradasEstoque();
                const productData = await getProducts();
                const fornecedorData = await fornecedorService.getFornecedores();

                const updatedEntradaData = entradaData.map(entrada => {
                    const product = productData.find(p => p.id === entrada.produtoId);
                    return {
                        ...entrada,
                        produtoNome: product ? product.nome : 'Produto Desconhecido',
                        valorCompra: formatCurrency(entrada.valorCompra),
                        frete: formatCurrency(entrada.frete),
                        imposto: formatCurrency(entrada.imposto),
                        data: formatDateForDisplay(entrada.data),
                        fornecedor: entrada.fornecedor // Remova a associação aqui
                    };
                });

                setEntries(updatedEntradaData);
                setFilteredEntries(updatedEntradaData);
                setProducts(productData);
                setFornecedores(fornecedorData);
            } catch (error) {
                console.error('Erro ao buscar dados:', error);
            }
        };

        fetchData();
    }, []);

    const parseCurrency = (value) => {
        if (typeof value === 'string') {
            return parseFloat(value.replace(/[^0-9,-]+/g, '').replace(',', '.'));
        }
        return parseFloat(value); // Adicione isso para tratar valores numéricos diretamente
    };

    const formatCurrencyForDB = (value) => {
        const parsedValue = parseCurrency(value);
        return isNaN(parsedValue) ? 0 : parsedValue.toFixed(2);
    };

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
    };

    const formatDateForDisplay = (date) => {
        const dateObj = new Date(date);
        // Ajusta para evitar problemas de fuso horário
        dateObj.setMinutes(dateObj.getMinutes() + dateObj.getTimezoneOffset());
        const day = String(dateObj.getDate()).padStart(2, '0');
        const month = String(dateObj.getMonth() + 1).padStart(2, '0');
        const year = dateObj.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const formatDateForDB = (date) => {
        const [day, month, year] = date.split('/');
        return `${year}-${month}-${day}`;
    };


    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);

    const handleSubmit = async (formData) => {
        try {
            const dataToSubmit = {
                ...formData,
                valorCompra: formatCurrencyForDB(formData.valorCompra),
                frete: formData.frete ? formatCurrencyForDB(formData.frete) : 0,
                imposto: formData.imposto ? formatCurrencyForDB(formData.imposto) : 0,
                data: formatDateForDB(formData.data)
            };

            const newEntry = await createEntradaEstoque(dataToSubmit);
            const product = products.find(p => p.id === newEntry.produtoId);
            const newEntryWithProductName = {
                ...newEntry,
                produtoNome: product ? product.nome : 'Produto Desconhecido',
                valorCompra: formatCurrency(newEntry.valorCompra),
                frete: formatCurrency(newEntry.frete),
                imposto: formatCurrency(newEntry.imposto),
                data: formatDateForDisplay(newEntry.data),
                fornecedor: newEntry.fornecedor
            };
            setEntries([...entries, newEntryWithProductName]);
            handleClose();
        } catch (error) {
            console.error('Erro ao adicionar entrada de estoque:', error);
        }
    };

    const handleEdit = async (id, updatedRow) => {
        try {
            const dataToSubmit = {
                ...updatedRow,
                valorCompra: formatCurrencyForDB(updatedRow.valorCompra),
                frete: updatedRow.frete ? formatCurrencyForDB(updatedRow.frete) : 0,
                imposto: updatedRow.imposto ? formatCurrencyForDB(updatedRow.imposto) : 0,
                data: formatDateForDB(updatedRow.data),
                fornecedor: updatedRow.fornecedor // Garantir que o valor do fornecedor é uma string
            };

            const updatedEntry = await updateEntradaEstoque(id, dataToSubmit);
            const product = products.find(p => p.id === updatedEntry.produtoId);
            const updatedEntryWithProductName = {
                ...updatedEntry,
                produtoNome: product ? product.nome : 'Produto Desconhecido',
                valorCompra: formatCurrency(updatedEntry.valorCompra),
                frete: formatCurrency(updatedEntry.frete),
                imposto: formatCurrency(updatedEntry.imposto),
                data: formatDateForDisplay(updatedEntry.data),
                fornecedor: updatedEntry.fornecedor // Usar diretamente o valor do fornecedor
            };

            setEntries(entries.map(entry => (entry.id === id ? updatedEntryWithProductName : entry)));
        } catch (error) {
            console.error('Erro ao atualizar entrada de estoque:', error);
        }
    };

    const handleDelete = async (id) => {
        try {
            await deleteEntradaEstoque(id);
            setEntries(entries.filter(entry => entry.id !== id));
        } catch (error) {
            console.error('Erro ao deletar entrada de estoque:', error);
        }
    };

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilter({ ...filter, [name]: value });
    };

    const applyFilters = () => {
        let filtered = [...entries];

        if (filter.productName) {
            filtered = filtered.filter(entry => entry.produtoNome.toLowerCase().includes(filter.productName.toLowerCase()));
        }

        if (filter.startDate) {
            const startDate = new Date(filter.startDate);
            filtered = filtered.filter(entry => new Date(entry.data.split('/').reverse().join('-')) >= startDate);
        }

        if (filter.endDate) {
            const endDate = new Date(filter.endDate);
            filtered = filtered.filter(entry => new Date(entry.data.split('/').reverse().join('-')) <= endDate);
        }

        setFilteredEntries(filtered);
    };

    useEffect(() => {
        applyFilters();
    }, [filter, entries]);

    return (
        <div>
            <Navbar />
            <div className="controle_entradas_container mt-5">
                <div className="d-flex justify-content-between align-items-center mb-4">
                    <h1 className="controle_entradas_title">Controle de Estoque | Entradas</h1>
                    <div className="controle_entradas_button-group">
                        <Button className="controle_entradas_btn-custom" onClick={handleShow}>
                            Adicionar Entrada
                        </Button>
                        <Button variant="danger" onClick={() => navigate(-1)} className="controle_entradas_btn-voltar">
                            Voltar
                        </Button>
                    </div>
                </div>
                <div className="mb-4">
                    <Form>
                        <Row>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Nome do Produto</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="productName"
                                        value={filter.productName}
                                        onChange={handleFilterChange}
                                        placeholder="Filtrar por nome do produto"
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group>
                                    <Form.Label>Data Início</Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="startDate"
                                        value={filter.startDate}
                                        onChange={handleFilterChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group>
                                    <Form.Label>Data Fim</Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="endDate"
                                        value={filter.endDate}
                                        onChange={handleFilterChange}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </div>
                <DynamicTable
                    columns={[
                        { key: 'produtoNome', label: 'Produto', editable: false },
                        { key: 'valorCompra', label: 'Valor de Compra' },
                        { key: 'quantidade', label: 'Quantidade' },
                        {
                            key: 'fornecedor',
                            label: 'Fornecedor',
                            type: 'select',
                            options: fornecedores.map(fornecedor => ({ value: fornecedor.fornecedor, label: fornecedor.fornecedor }))
                        },
                        { key: 'frete', label: 'Frete' },
                        { key: 'imposto', label: 'Imposto' },
                        { key: 'data', label: 'Data', type: 'date' }
                    ]}
                    data={filteredEntries}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                />
            </div>
            <FormModal
                showModal={showModal}
                handleClose={handleClose}
                handleSubmit={handleSubmit}
                products={products}
                fornecedores={fornecedores}
            />
        </div>
    );
}

export default ControleDeEstoqueEntradas;
