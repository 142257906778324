import api from '../api';

const cityService = {
    async getCities() {
        const response = await api.get('/cities');
        return response.data;
    },
    async addCity(cidade, cep) {
        const response = await api.post('/cities', { cidade, cep });
        return response.data;
    },
    async updateCity(id, cidade, cep) {
        const response = await api.put(`/cities/${id}`, { cidade, cep });
        return response.data;
    },
    async deleteCity(id) {
        const response = await api.delete(`/cities/${id}`);
        return response.data;
    }
};

export default cityService;
