import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from '../../Common/Navbar';
import DynamicTable from '../../Common/table/DynamicTable';
import { Button, Form, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { getSaidasEstoque, createSaidaEstoque, updateSaidaEstoque, deleteSaidaEstoque } from '../../services/estoqueSaidasService';
import { getProducts } from '../../services/productService';
import FormModal from './components/FormModal';
import './Controle_de_Estoque_Saidas.css';

const tiposDeSaida = [
    'Venda de Produtos',
    'Transferência entre Armazéns',
    'Devoluções',
    'Uso Interno',
    'Doações',
    'Perdas',
    'Amostras',
    'Consumo em Produção',
    'Descarte',
    'Troca',
    'Empréstimo',
    'Bônus ou Incentivos'
];

function ControleDeEstoqueSaidas() {
    const navigate = useNavigate();

    const [showModal, setShowModal] = useState(false);
    const [entries, setEntries] = useState([]);
    const [products, setProducts] = useState([]);
    const [filteredEntries, setFilteredEntries] = useState([]);
    const [filter, setFilter] = useState({
        productName: '',
        startDate: '',
        endDate: ''
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const saidaData = await getSaidasEstoque();
                const productData = await getProducts();

                const updatedSaidaData = saidaData.map(saida => {
                    const product = productData.find(p => p.id === saida.produtoId);
                    return {
                        ...saida,
                        produtoNome: product ? product.nome : 'Produto Desconhecido',
                        valorVenda: formatCurrency(saida.valorVenda),
                        data: formatDateForDisplay(saida.data),
                    };
                });

                setEntries(updatedSaidaData);
                setFilteredEntries(updatedSaidaData);
                setProducts(productData);
            } catch (error) {
                console.error('Erro ao buscar dados:', error);
            }
        };

        fetchData();
    }, []);

    const parseCurrency = (value) => {
        if (typeof value === 'string') {
            return parseFloat(value.replace(/\./g, '').replace(',', '.'));
        }
        return parseFloat(value);
    };

    const formatCurrencyForDB = (value) => {
        const parsedValue = parseCurrency(value);
        return isNaN(parsedValue) ? 0 : parsedValue.toFixed(2);
    };

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
    };

    const formatDateForDisplay = (date) => {
        return new Intl.DateTimeFormat('pt-BR').format(new Date(date));
    };

    const formatDateForDB = (date) => {
        if (!date) {
            const now = new Date();
            const day = String(now.getDate()).padStart(2, '0');
            const month = String(now.getMonth() + 1).padStart(2, '0');
            const year = now.getFullYear();
            return `${year}-${month}-${day}`;
        }
        const [day, month, year] = date.split('/');
        return `${year}-${month}-${day}`;
    };

    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);

    const handleSubmit = async (formData) => {
        try {
            const dataToSubmit = {
                ...formData,
                valorVenda: formatCurrencyForDB(formData.valorVenda), // Apenas formatar valorVenda
                data: formatDateForDB(formData.data)
            };
            console.log('Dados enviados para o backend:', dataToSubmit); // Adicionar log para verificar dados enviados
            const newSaida = await createSaidaEstoque(dataToSubmit);
            const product = products.find(p => p.id === newSaida.produtoId);
            const newSaidaWithProductName = {
                ...newSaida,
                produtoNome: product ? product.nome : 'Produto Desconhecido',
                valorVenda: formatCurrency(newSaida.valorVenda),
                data: formatDateForDisplay(newSaida.data),
            };
            setEntries([...entries, newSaidaWithProductName]);
            handleClose();
        } catch (error) {
            console.error('Erro ao adicionar saída de estoque:', error);
        }
    };

    const handleEdit = async (id, updatedRow) => {
        try {
            const dataToSubmit = {
                ...updatedRow,
                valorVenda: formatCurrencyForDB(updatedRow.valorVenda), // Apenas formatar valorVenda
                data: formatDateForDB(updatedRow.data)
            };
            console.log('Dados enviados para edição no backend:', dataToSubmit); // Adicionar log para verificar dados enviados
            const updatedSaida = await updateSaidaEstoque(id, dataToSubmit);
            const product = products.find(p => p.id === updatedSaida.produtoId);
            const updatedSaidaWithProductName = {
                ...updatedSaida,
                produtoNome: product ? product.nome : 'Produto Desconhecido',
                valorVenda: formatCurrency(updatedSaida.valorVenda),
                data: formatDateForDisplay(updatedSaida.data),
            };
            setEntries(entries.map(entry => (entry.id === id ? updatedSaidaWithProductName : entry)));
        } catch (error) {
            console.error('Erro ao atualizar saída de estoque:', error);
        }
    };

    const handleDelete = async (id) => {
        try {
            await deleteSaidaEstoque(id);
            setEntries(entries.filter(entry => entry.id !== id));
        } catch (error) {
            console.error('Erro ao deletar saída de estoque:', error);
        }
    };

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilter({ ...filter, [name]: value });
    };

    const applyFilters = () => {
        let filtered = [...entries];

        if (filter.productName) {
            filtered = filtered.filter(entry => entry.produtoNome.toLowerCase().includes(filter.productName.toLowerCase()));
        }

        if (filter.startDate) {
            const startDate = new Date(filter.startDate);
            filtered = filtered.filter(entry => new Date(entry.data.split('/').reverse().join('-')) >= startDate);
        }

        if (filter.endDate) {
            const endDate = new Date(filter.endDate);
            filtered = filtered.filter(entry => new Date(entry.data.split('/').reverse().join('-')) <= endDate);
        }

        setFilteredEntries(filtered);
    };

    useEffect(() => {
        applyFilters();
    }, [filter, entries]);

    return (
        <div>
            <Navbar />
            <div className="controle_saidas_container mt-5">
                <div className="d-flex justify-content-between align-items-center mb-4">
                    <h1 className="controle_saidas_title">Controle de Estoque | Saídas</h1>
                    <div className="controle_saidas_button-group">
                        <Button className="controle_saidas_btn-custom" onClick={handleShow}>
                            Adicionar Saída
                        </Button>
                        <Button variant="danger" onClick={() => navigate(-1)} className="controle_saidas_btn-voltar">
                            Voltar
                        </Button>
                    </div>
                </div>
                <div className="mb-4">
                    <Form>
                        <Row>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Nome do Produto</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="productName"
                                        value={filter.productName}
                                        onChange={handleFilterChange}
                                        placeholder="Filtrar por nome do produto"
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group>
                                    <Form.Label>Data Início</Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="startDate"
                                        value={filter.startDate}
                                        onChange={handleFilterChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group>
                                    <Form.Label>Data Fim</Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="endDate"
                                        value={filter.endDate}
                                        onChange={handleFilterChange}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </div>
                <DynamicTable
                    columns={[
                        { key: 'produtoNome', label: 'Produto', editable: false },
                        { key: 'valorVenda', label: 'Valor de Venda' },
                        { key: 'quantidade', label: 'Quantidade' },
                        {
                            key: 'tipoSaida',
                            label: 'Tipo de Saída',
                            type: 'select',
                            options: tiposDeSaida.map(tipo => ({ value: tipo, label: tipo }))
                        },
                        { key: 'data', label: 'Data', type: 'date' }
                    ]}
                    data={filteredEntries}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                />
            </div>
            <FormModal
                showModal={showModal}
                handleClose={handleClose}
                handleSubmit={handleSubmit}
                products={products}
            />
        </div>
    );
}

export default ControleDeEstoqueSaidas;
