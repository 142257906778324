import axios from 'axios';

const apiHost = import.meta.env.VITE_API_HOST;
const serverUrl = `${apiHost}`;

const api = axios.create({
  baseURL: `${serverUrl}/api`
});

export default api;
export { serverUrl };
