import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import Navbar from '../../Common/Navbar';
import SidebarComponent from './components/Sidebar';
import MessageList from './components/MessageList';
import InputArea from './components/InputArea';
import defaultProfilePic from '../../assets/images/default-profile.png'; // Adicione a imagem de perfil padrão
import backgroundChatbot from '../../assets/images/background-chatbot.jpg'; // Adicione a imagem de background
import {chatbotEndpoint} from '../../scripts/config';

const ChatAppContainer = styled.div`
  display: flex;
  height: 100vh;
  background: #e0e0e0;
  font-family: 'Arial', sans-serif;
`;

const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-image: url(${backgroundChatbot}); /* Caminho para o background */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-left: ${props => (props.$sidebarOpen ? '300px' : '0')};
  transition: margin-left 0.3s;
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  background-color: #075e54;
  color: white;
  padding: 15px 20px;
  font-size: 24px;
  font-weight: bold;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const ProfileImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 15px;
`;

const HeaderText = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderName = styled.div`
  font-size: 18px;
  font-weight: bold;
`;

const HeaderStatus = styled.div`
  font-size: 14px;
  color: #d4d4d4;
`;

function ChatApp() {
  const [conversations, setConversations] = useState([]);
  const [activeConversation, setActiveConversation] = useState(null);
  const [messages, setMessages] = useState([]);
  const [sidebarOpen, setSidebarOpen] = useState(true);

  useEffect(() => {
    axios.get(`${chatbotEndpoint}/conversations`)
      .then(response => {
        setConversations(response.data);
        if (response.data.length > 0) {
          setActiveConversation(response.data[0]);
        }
      })
      .catch(error => {
        console.error('Error fetching conversations:', error);
      });
  }, []);

  useEffect(() => {
    if (activeConversation) {
      axios.get(`${chatbotEndpoint}/messages/${activeConversation.id}`)
        .then(response => {
          setMessages(response.data);
        })
        .catch(error => {
          console.error('Error fetching messages:', error);
        });
    }
  }, [activeConversation]);

  const sendMessage = (message) => {
    if (activeConversation) {
      axios.post(`${chatbotEndpoint}/messages`, {
        chatId: activeConversation.id,
        message: message.text
      })
        .then(() => {
          setMessages([...messages, message]);
        })
        .catch(error => {
          console.error('Error sending message:', error);
        });
    }
  };

  return (
    <ChatAppContainer>
      <Helmet>
        <title>Sparkfluxe Chatbot | ADM</title>
      </Helmet>
      <SidebarComponent
        conversations={conversations}
        activeConversation={activeConversation}
        setActiveConversation={setActiveConversation}
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      />
      <ChatContainer $sidebarOpen={sidebarOpen}>
        <Navbar />
        {activeConversation && (
          <Header>
            <ProfileImage src={activeConversation.profilePicUrl || defaultProfilePic} alt="Profile" />
            <HeaderText>
              <HeaderName>{activeConversation.name}</HeaderName>
              <HeaderStatus>Online</HeaderStatus>
            </HeaderText>
          </Header>
        )}
        <MessageList messages={messages} />
        <InputArea
          messages={messages}
          setMessages={setMessages}
          sendMessage={sendMessage}
        />
      </ChatContainer>
    </ChatAppContainer>
  );
}

export default ChatApp;
