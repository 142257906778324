import React, { useEffect, useState, useRef } from 'react';
import Navbar from '../../Common/Navbar';
import Chart from 'chart.js/auto';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import 'bootstrap/dist/css/bootstrap.min.css';
import './PainelFinanceiro.css';

const PainelFinanceiro = () => {
    const [mes, setMes] = useState(new Date().getMonth());
    const [ano, setAno] = useState(new Date().getFullYear());
    const [dados, setDados] = useState({
        ticketMedio: 0,
        faturamento: 0,
        lucro: 0,
        vendas: 0,
        despesas: 0,
        previsaoFaturamento: 0,
        previsaoLucro: 0,
        previsaoDespesas: 0,
        previsaoVendas: 0
    });

    const chartFaturamentoRef = useRef(null);
    const chartLucroRef = useRef(null);
    const chartDespesasRef = useRef(null);

    useEffect(() => {
        buscarDados(mes, ano);
    }, [mes, ano]);

    useEffect(() => {
        renderizarGraficos();
    }, [dados]);

    const buscarDados = (mes, ano) => {
        // Aqui você pode buscar dados do backend para o mês e ano selecionados
        // Para este exemplo, usaremos dados estáticos

        const dadosBuscados = {
            ticketMedio: 150,
            faturamento: 25000,
            lucro: 10000,
            despesas: 5000,
            vendas: 150,
            previsaoFaturamento: 27000,
            previsaoLucro: 11000,
            previsaoDespesas: 5500,
            previsaoVendas: 160
        };

        const mesAtual = (new Date().getMonth() === mes && new Date().getFullYear() === ano);

        setDados({
            ticketMedio: dadosBuscados.ticketMedio,
            faturamento: mesAtual ? dadosBuscados.previsaoFaturamento : dadosBuscados.faturamento,
            lucro: mesAtual ? dadosBuscados.previsaoLucro : dadosBuscados.lucro,
            despesas: mesAtual ? dadosBuscados.previsaoDespesas : dadosBuscados.despesas,
            vendas: mesAtual ? dadosBuscados.previsaoVendas : dadosBuscados.vendas
        });
    };

    const formatarMoeda = (valor) => {
        return `R$ ${valor.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&.').replace('.', ',')}`;
    };

    const renderizarGraficos = () => {
        if (chartFaturamentoRef.current) {
            chartFaturamentoRef.current.destroy();
        }
        if (chartLucroRef.current) {
            chartLucroRef.current.destroy();
        }
        if (chartDespesasRef.current) {
            chartDespesasRef.current.destroy();
        }

        const ctxFaturamento = document.getElementById('graficoFaturamento').getContext('2d');
        const ctxLucro = document.getElementById('graficoLucro').getContext('2d');
        const ctxDespesas = document.getElementById('graficoDespesas').getContext('2d');

        // Gráfico de Faturamento
        chartFaturamentoRef.current = new Chart(ctxFaturamento, {
            type: 'line',
            data: {
                labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
                datasets: [{
                    label: 'Faturamento (R$)',
                    data: [2000, 4000, 3000, 5000, 7000, 6000, dados.faturamento],
                    backgroundColor: 'rgba(54, 162, 235, 0.2)',
                    borderColor: 'rgba(54, 162, 235, 1)',
                    borderWidth: 2,
                    pointBackgroundColor: 'rgba(255, 99, 132, 1)',
                    pointBorderColor: '#fff',
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: 'rgba(255, 99, 132, 1)',
                    pointRadius: 5,
                    pointHoverRadius: 7
                }]
            },
            options: {
                responsive: true,
                scales: {
                    x: {
                        grid: {
                            display: false
                        },
                        ticks: {
                            font: {
                                size: 14,
                                weight: 'bold'
                            }
                        }
                    },
                    y: {
                        beginAtZero: true,
                        grid: {
                            color: 'rgba(200, 200, 200, 0.3)'
                        },
                        ticks: {
                            font: {
                                size: 14,
                                weight: 'bold'
                            }
                        }
                    }
                },
                plugins: {
                    legend: {
                        display: true,
                        labels: {
                            color: 'rgba(0, 0, 0, 0.7)',
                            font: {
                                size: 16,
                                weight: 'bold'
                            }
                        }
                    },
                    tooltip: {
                        backgroundColor: 'rgba(0, 0, 0, 0.7)',
                        titleFont: {
                            size: 16,
                            weight: 'bold'
                        },
                        bodyFont: {
                            size: 14
                        },
                        borderColor: 'rgba(255, 99, 132, 1)',
                        borderWidth: 1
                    }
                }
            }
        });

        // Gráfico de Lucro
        chartLucroRef.current = new Chart(ctxLucro, {
            type: 'bar',
            data: {
                labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
                datasets: [{
                    label: 'Lucro (R$)',
                    data: [1000, 2000, 1500, 2500, 3500, 3000, dados.lucro],
                    backgroundColor: 'rgba(75, 192, 192, 0.2)',
                    borderColor: 'rgba(75, 192, 192, 1)',
                    borderWidth: 2,
                    borderRadius: 10,
                    hoverBackgroundColor: 'rgba(75, 192, 192, 0.4)',
                    hoverBorderColor: 'rgba(75, 192, 192, 1)',
                }]
            },
            options: {
                responsive: true,
                scales: {
                    x: {
                        grid: {
                            display: false
                        },
                        ticks: {
                            font: {
                                size: 14,
                                weight: 'bold'
                            }
                        }
                    },
                    y: {
                        beginAtZero: true,
                        grid: {
                            color: 'rgba(200, 200, 200, 0.3)'
                        },
                        ticks: {
                            font: {
                                size: 14,
                                weight: 'bold'
                            }
                        }
                    }
                },
                plugins: {
                    legend: {
                        display: true,
                        labels: {
                            color: 'rgba(0, 0, 0, 0.7)',
                            font: {
                                size: 16,
                                weight: 'bold'
                            }
                        }
                    },
                    tooltip: {
                        backgroundColor: 'rgba(0, 0, 0, 0.7)',
                        titleFont: {
                            size: 16,
                            weight: 'bold'
                        },
                        bodyFont: {
                            size: 14
                        },
                        borderColor: 'rgba(75, 192, 192, 1)',
                        borderWidth: 1
                    }
                }
            }
        });

        // Gráfico de Despesas
        chartDespesasRef.current = new Chart(ctxDespesas, {
            type: 'line',
            data: {
                labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
                datasets: [{
                    label: 'Despesas (R$)',
                    data: [500, 1000, 800, 1500, 2000, 1800, dados.despesas],
                    backgroundColor: 'rgba(255, 206, 86, 0.2)',
                    borderColor: 'rgba(255, 206, 86, 1)',
                    borderWidth: 2,
                    pointBackgroundColor: 'rgba(75, 192, 192, 1)',
                    pointBorderColor: '#fff',
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: 'rgba(75, 192, 192, 1)',
                    pointRadius: 5,
                    pointHoverRadius: 7
                }]
            },
            options: {
                responsive: true,
                scales: {
                    x: {
                        grid: {
                            display: false
                        },
                        ticks: {
                            font: {
                                size: 14,
                                weight: 'bold'
                            }
                        }
                    },
                    y: {
                        beginAtZero: true,
                        grid: {
                            color: 'rgba(200, 200, 200, 0.3)'
                        },
                        ticks: {
                            font: {
                                size: 14,
                                weight: 'bold'
                            }
                        }
                    }
                },
                plugins: {
                    legend: {
                        display: true,
                        labels: {
                            color: 'rgba(0, 0, 0, 0.7)',
                            font: {
                                size: 16,
                                weight: 'bold'
                            }
                        }
                    },
                    tooltip: {
                        backgroundColor: 'rgba(0, 0, 0, 0.7)',
                        titleFont: {
                            size: 16,
                            weight: 'bold'
                        },
                        bodyFont: {
                            size: 14
                        },
                        borderColor: 'rgba(255, 206, 86, 1)',
                        borderWidth: 1
                    }
                }
            }
        });
    };

    const downloadPDF = () => {
        const input = document.getElementById('container');
        const clone = input.cloneNode(true);

        // Remover os botões do clone
        const buttons = clone.querySelectorAll('button');
        buttons.forEach(button => button.remove());

        // Criar um container temporário para renderizar o clone
        const tempContainer = document.createElement('div');
        tempContainer.style.position = 'absolute';
        tempContainer.style.top = '-9999px';
        tempContainer.appendChild(clone);
        document.body.appendChild(tempContainer);

        html2canvas(clone).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');
            const imgProps = pdf.getImageProperties(imgData);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
            pdf.save(`relatorio_financeiro_${meses[mes]}_${ano}.pdf`);

            // Remover o container temporário após a geração do PDF
            document.body.removeChild(tempContainer);
        });
    };

    const meses = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
    const anoAtual = new Date().getFullYear();
    const mesAtual = new Date().getMonth();

    return (
        <div className="financeiro_page" id="relatorioFinanceiro">
            <Navbar />
            <div className="container" id="container">
                <h1 className="financeiro_page text-center mb-5">Painel Financeiro | SparkFluxe</h1>
                <div className="financeiro_page month-selector text-center mb-4">
                    <label htmlFor="mes">Selecione o mês:</label>
                    <select id="mes" className="financeiro_page form-select d-inline w-auto mx-2" value={mes} onChange={e => setMes(parseInt(e.target.value))}>
                        {meses.slice(0, ano === anoAtual ? new Date().getMonth() + 1 : 12).map((mes, index) => (
                            <option key={index} value={index}>{mes}</option>
                        ))}
                    </select>
                    <label htmlFor="ano">Selecione o ano:</label>
                    <select id="ano" className="financeiro_page form-select d-inline w-auto mx-2" value={ano} onChange={e => setAno(parseInt(e.target.value))}>
                        {[...Array(6)].map((_, i) => (
                            <option key={i} value={anoAtual - i}>{anoAtual - i}</option>
                        ))}
                    </select>
                    <button className="financeiro_page button-forecast" onClick={() => window.location.href = `/forecast?mes=${mes}&ano=${ano}`}>Ver Previsões</button>
                </div>
                <div className="financeiro_page row">
                    <div className="financeiro_page col-md-3">
                        <div className="financeiro_page card">
                            <div className="financeiro_page card-header text-center">
                                <h4>Ticket Médio</h4>
                            </div>
                            <div className="financeiro_page card-body text-center">
                                <h5>{formatarMoeda(dados.ticketMedio)}</h5>
                            </div>
                        </div>
                    </div>
                    <div className="financeiro_page col-md-3">
                        <div className="financeiro_page card">
                            <div className="financeiro_page card-header text-center">
                                <h4>Faturamento</h4>
                            </div>
                            <div className="financeiro_page card-body text-center">
                                <h5>{formatarMoeda(dados.faturamento)}</h5>
                            </div>
                        </div>
                    </div>
                    <div className="financeiro_page col-md-3">
                        <div className="financeiro_page card">
                            <div className="financeiro_page card-header text-center">
                                <h4>Lucro</h4>
                            </div>
                            <div className="financeiro_page card-body text-center">
                                <h5>{formatarMoeda(dados.lucro)}</h5>
                            </div>
                        </div>
                    </div>
                    <div className="financeiro_page col-md-3">
                        <div className="financeiro_page card">
                            <div className="financeiro_page card-header text-center">
                                <h4>Despesas</h4>
                            </div>
                            <div className="financeiro_page card-body text-center">
                                <h5>{formatarMoeda(dados.despesas)}</h5>
                            </div>
                        </div>
                    </div>
                    <div className="financeiro_page col-md-3">
                        <div className="financeiro_page card">
                            <div className="financeiro_page card-header text-center">
                                <h4>Número de Vendas</h4>
                            </div>
                            <div className="financeiro_page card-body text-center">
                                <h5>{dados.vendas}</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="financeiro_page row mt-4">
                    <div className="financeiro_page col-md-12">
                        <div className="financeiro_page card">
                            <div className="financeiro_page card-header">
                                <h4 className="financeiro_page text-center">Gráficos de Desempenho</h4>
                            </div>
                            <div className="financeiro_page card-body">
                                <div className="financeiro_page chart-container">
                                    <canvas id="graficoFaturamento"></canvas>
                                </div>
                                <div className="financeiro_page chart-container mt-4">
                                    <canvas id="graficoLucro"></canvas>
                                </div>
                                <div className="financeiro_page chart-container mt-4">
                                    <canvas id="graficoDespesas"></canvas>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="text-center mt-4">
                    <button className="btn btn-primary" onClick={downloadPDF}>Baixar Relatório em PDF</button>
                </div>
            </div>
        </div>
    );
}

export default PainelFinanceiro;
