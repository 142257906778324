import React, { useState, useEffect } from 'react';
import fornecedorService from '../../../../../../services/configuracoes_do_sistema/fornecedorService';
import styles from './fornecedoresSettings.module.css';

const FornecedoresSettings = () => {
    const [fornecedores, setFornecedores] = useState([]);
    const [fornecedor, setFornecedor] = useState('');
    const [editIndex, setEditIndex] = useState(null);
    const [editFornecedor, setEditFornecedor] = useState('');

    useEffect(() => {
        fetchFornecedores();
    }, []);

    const fetchFornecedores = async () => {
        try {
            const data = await fornecedorService.getFornecedores();
            setFornecedores(data);
        } catch (error) {
            console.error('Erro ao buscar fornecedores:', error);
        }
    };

    const addFornecedor = async () => {
        if (fornecedor) {
            try {
                const newFornecedor = await fornecedorService.addFornecedor(fornecedor);
                setFornecedores([...fornecedores, newFornecedor]);
                setFornecedor('');
            } catch (error) {
                console.error('Erro ao adicionar fornecedor:', error);
            }
        }
    };

    const startEditFornecedor = (index) => {
        setEditIndex(index);
        setEditFornecedor(fornecedores[index].fornecedor);
    };

    const confirmEditFornecedor = async (index) => {
        try {
            await fornecedorService.updateFornecedor(fornecedores[index].id, editFornecedor);
            const newFornecedores = fornecedores.map((f, i) =>
                i === index ? { ...f, fornecedor: editFornecedor } : f
            );
            setFornecedores(newFornecedores);
            setEditIndex(null);
            setEditFornecedor('');
        } catch (error) {
            console.error('Erro ao atualizar fornecedor:', error);
        }
    };

    const cancelEditFornecedor = () => {
        setEditIndex(null);
        setEditFornecedor('');
    };

    const handleKeyPress = (e, index) => {
        if (e.key === 'Enter') {
            confirmEditFornecedor(index);
        } else if (e.key === 'Escape') {
            cancelEditFornecedor();
        }
    };

    const deleteFornecedor = async (index) => {
        try {
            await fornecedorService.deleteFornecedor(fornecedores[index].id);
            const newFornecedores = fornecedores.filter((_, i) => i !== index);
            setFornecedores(newFornecedores);
        } catch (error) {
            console.error('Erro ao deletar fornecedor:', error);
        }
    };

    return (
        <div className={styles['fornecedores-settings-container']}>
            <h1 className={styles['fornecedores-settings-h1']}>Adicionar Fornecedores</h1>
            <div className={styles['fornecedores-settings-formGroup']}>
                <input
                    type="text"
                    value={fornecedor}
                    onChange={(e) => setFornecedor(e.target.value)}
                    placeholder="Fornecedor"
                    className={styles['fornecedores-settings-input']}
                />
                <button onClick={addFornecedor} className={styles['fornecedores-settings-addButton']}>Adicionar</button>
            </div>
            <div className={styles['fornecedores-settings-list']}>
                {fornecedores.map((fornecedorItem, index) => (
                    <div key={index} className={styles['fornecedores-settings-item']}>
                        {editIndex === index ? (
                            <>
                                <input
                                    type="text"
                                    value={editFornecedor}
                                    onChange={(e) => setEditFornecedor(e.target.value)}
                                    onKeyDown={(e) => handleKeyPress(e, index)}
                                    className={styles['fornecedores-settings-input']}
                                    autoFocus
                                />
                                <button onClick={() => confirmEditFornecedor(index)} className={styles['fornecedores-settings-confirmButton']}>✔️</button>
                                <button onClick={cancelEditFornecedor} className={styles['fornecedores-settings-cancelButton']}>✖️</button>
                            </>
                        ) : (
                            <>
                                <span>{fornecedorItem.fornecedor}</span>
                                <button className={styles['fornecedores-settings-editButton']} onClick={() => startEditFornecedor(index)}>✏️</button>
                            </>
                        )}
                        <button className={styles['fornecedores-settings-deleteButton']} onClick={() => deleteFornecedor(index)}>🗑️</button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FornecedoresSettings;
