import React from 'react';
import styled, { keyframes } from 'styled-components';
import { FaBars, FaTimes } from 'react-icons/fa';
import defaultProfilePic from '../../../assets/images/default-profile.png'; // Adicione a imagem de perfil padrão

const slideIn = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
`;

const slideOut = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`;

const SidebarContainer = styled.div`
  position: relative;
`;

const StyledSidebar = styled.div`
  width: ${props => (props.$isOpen ? '300px' : '0')};
  animation: ${props => (props.$isOpen ? slideIn : slideOut)} 0.3s forwards;
  background: linear-gradient(135deg, #2c3e50, #4c669f);
  color: white;
  display: flex;
  flex-direction: column;
  height: 100vh;
  box-shadow: 3px 0 15px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: absolute;
  z-index: 1;
`;

const SidebarToggle = styled.div`
  position: absolute;
  top: 20px;
  right: ${props => (props.$isOpen ? '-50px' : '-40px')};
  background: #34495e;
  color: white;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: right 0.3s;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
`;

const Header = styled.header`
  background-color: #2c3e50;
  color: white;
  padding: 15px 20px;
  font-size: 24px;
  font-weight: bold;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

const ConversationList = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
  margin-top: 20px;
`;

const ConversationItem = styled.div`
  display: flex;
  align-items: center;
  padding: 15px;
  margin-bottom: 10px;
  background: ${props => (props.active ? '#1abc9c' : 'rgba(255, 255, 255, 0.1)')};
  border-radius: 10px;
  cursor: pointer;
  transition: background 0.3s;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  &:hover {
    background: #1abc9c;
  }
`;

const ConversationDetails = styled.div`
  margin-left: 10px;
  flex-grow: 1;
`;

const ProfilePic = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 50%;
`;

function Sidebar({ conversations, activeConversation, setActiveConversation, sidebarOpen, setSidebarOpen }) {
  return (
    <SidebarContainer>
      <StyledSidebar $isOpen={sidebarOpen}>
        <Header>Conversas</Header>
        <ConversationList>
          {conversations.map(conversation => (
            <ConversationItem
              key={conversation.id}
              active={activeConversation && activeConversation.id === conversation.id}
              onClick={() => setActiveConversation(conversation)}
            >
              <ProfilePic src={conversation.profilePicUrl || defaultProfilePic} alt="Profile" />
              <ConversationDetails>
                <div>{conversation.name}</div>
                <div style={{ fontSize: '12px', color: '#bdc3c7' }}>{conversation.lastMessage}</div>
              </ConversationDetails>
            </ConversationItem>
          ))}
        </ConversationList>
      </StyledSidebar>
      <SidebarToggle $isOpen={sidebarOpen} onClick={() => setSidebarOpen(!sidebarOpen)}>
        {sidebarOpen ? <FaTimes /> : <FaBars />}
      </SidebarToggle>
    </SidebarContainer>
  );
}

export default Sidebar;
