import React, { useEffect, useState } from 'react';
import { Modal, Button, ListGroup, ListGroupItem } from 'react-bootstrap';
import './CityModal.css';

const CityModal = ({ show, onHide, selectedCard, updateAnuncio, handleCitiesChange, handleActivatedCitiesChange, cities, activatedCities, allCities }) => {
    useEffect(() => {
        if (selectedCard && selectedCard.cidade) {
            const activeCities = selectedCard.cidade.split(',').map(cep => cep.trim());
            handleCitiesChange(selectedCard.id, activeCities);
            handleActivatedCitiesChange(selectedCard.id, allCities
                .filter(city => activeCities.includes(city.cep))
                .map(city => city.id));
        }
    }, [selectedCard, allCities]); // Certifique-se de que essas dependências sejam corretas e não causem loop infinito

    const handleActivateCity = async (city) => {
        try {
            const updatedCities = [...cities, city.cep];
            handleCitiesChange(selectedCard.id, updatedCities);

            const anuncio = {
                ...selectedCard,
                cidade: updatedCities.join(',')
            };

            await updateAnuncio(selectedCard.id, anuncio);

            handleActivatedCitiesChange(selectedCard.id, [...activatedCities, city.id]);
        } catch (error) {
            console.error('Erro ao ativar cidade:', error);
        }
    };

    const handleDeactivateCity = async (city) => {
        try {
            const updatedCities = cities.filter(cep => cep !== city.cep);
            handleCitiesChange(selectedCard.id, updatedCities);

            const anuncio = {
                ...selectedCard,
                cidade: updatedCities.join(',')
            };

            await updateAnuncio(selectedCard.id, anuncio);

            handleActivatedCitiesChange(selectedCard.id, activatedCities.filter(id => id !== city.id));
        } catch (error) {
            console.error('Erro ao desativar cidade:', error);
        }
    };

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>Cidades de Atuação</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ListGroup>
                    {allCities.map(city => (
                        <ListGroupItem key={city.id} className="city-item">
                            <span>{city.cidade}</span>
                            <Button
                                variant={activatedCities.includes(city.id) ? "secondary" : "success"}
                                className="activate-button"
                                onClick={() =>
                                    activatedCities.includes(city.id)
                                        ? handleDeactivateCity(city)
                                        : handleActivateCity(city)
                                }
                            >
                                {activatedCities.includes(city.id) ? "Desativar" : "Ativar"}
                            </Button>
                        </ListGroupItem>
                    ))}
                </ListGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>Fechar</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CityModal;
