import axios from 'axios';
import { serverUrl } from './api';

export const getAnuncios = async () => {
  try {
    const response = await axios.get(`${serverUrl}/api/anuncios`);
    return response.data;
  } catch (error) {
    console.error("Erro ao buscar anúncios:", error);
    throw error;
  }
};

export const updateAnuncio = async (id, anuncio) => {
  try {
    const response = await axios.put(`${serverUrl}/api/anuncios/${id}`, anuncio, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    console.error(`Erro ao editar anúncio ${id}:`, error);
    throw error;
  }
};

export const addImages = async (id, images) => {
  const formData = new FormData();
  images.forEach((image, index) => {
    formData.append('imagens', image);
    formData.append('index', index);
  });

  // Create a mapping of image names to indices
  const indexMapping = images.reduce((acc, image, index) => {
    acc[image.name] = index;
    return acc;
  }, {});

  formData.append('index', JSON.stringify(indexMapping));

  try {
    const response = await axios.post(`${serverUrl}/api/anuncios/${id}/imagens`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  } catch (error) {
    console.error("Erro ao adicionar imagens:", error);
    throw error;
  }
};

export const removeImage = async (id, imageIndex) => {
  try {
    const response = await axios.delete(`${serverUrl}/api/anuncios/${id}/imagens/${imageIndex}`);
    return response.data;
  } catch (error) {
    console.error("Erro ao remover imagem:", error);
    throw error;
  }
};

export const updateImagesOrder = async (id, reorderedImages) => {
  try {
    const response = await axios.post(`${serverUrl}/api/anuncios/${id}/update-images-order`, { reorderedImages });
    return response.data;
  } catch (error) {
    console.error("Erro ao atualizar a ordem das imagens:", error);
    throw error;
  }
};
