import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { FaExpand, FaTimes } from 'react-icons/fa';

const MessagesArea = styled.div`
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  background: #e5ddd5; /* WhatsApp background color */
`;

const Message = styled.div`
  background-color: ${props => (props.$isUser ? '#dcf8c6' : '#fff')}; /* WhatsApp message colors */
  color: ${props => (props.$isUser ? '#000' : '#000')};
  padding: 10px;
  border-radius: 7.5px;
  margin: 10px 0;
  max-width: 80%;
  align-self: ${props => (props.$isUser ? 'flex-end' : 'flex-start')};
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.06);
  position: relative;
  word-wrap: break-word;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    ${props => (props.$isUser ? 'right: -5px;' : 'left: -5px;')}
    width: 0;
    height: 0;
    border-style: solid;
    border-width: ${props => (props.$isUser ? '0 0 8px 8px' : '0 8px 8px 0')};
    border-color: ${props => (props.$isUser ? 'transparent transparent #dcf8c6 transparent' : 'transparent #fff transparent transparent')};
  }
`;

const MessageText = styled.div`
  margin-bottom: 5px;
  white-space: pre-wrap; /* Preserve whitespace */
`;

const MediaContainer = styled.div`
  display: flex;
  justify-content: ${props => (props.$isUser ? 'flex-end' : 'flex-start')};
  align-items: center;
  margin-top: 5px;
`;

const ImagePreview = styled.img`
  max-width: 150px;
  max-height: 150px;
  border-radius: 7.5px;
  cursor: pointer;
  object-fit: cover;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.06);
`;

const ExpandIcon = styled(FaExpand)`
  cursor: pointer;
  margin-left: 5px;
  color: #4a67a1;
`;

const Timestamp = styled.div`
  font-size: 10px;
  text-align: ${props => (props.$isUser ? 'right' : 'left')};
  margin-top: 5px;
  color: #999;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  position: relative;
  max-width: 90%;
  max-height: 90%;
  background: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const ModalImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 10px;
  object-fit: cover;
`;

const CloseButton = styled(FaTimes)`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
`;

function MessageList({ messages }) {
  const messagesEndRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImageUrl, setModalImageUrl] = useState('');

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const handleImageClick = (imageUrl) => {
    setModalImageUrl(imageUrl);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalImageUrl('');
  };

  return (
    <MessagesArea>
      {messages.map((msg, idx) => (
        <Message key={idx} $isUser={msg.isUser}>
          <MessageText>{msg.text}</MessageText>
          <MediaContainer $isUser={msg.isUser}>
            {msg.imageUrl && (
              <>
                <ImagePreview src={msg.imageUrl} alt="Image" onClick={() => handleImageClick(msg.imageUrl)} />
                <ExpandIcon onClick={() => handleImageClick(msg.imageUrl)} />
              </>
            )}
            {msg.audioUrl && (
              <audio controls src={msg.audioUrl} style={{ marginTop: '10px' }} />
            )}
            {msg.fileUrl && (
              <a href={msg.fileUrl} target="_blank" rel="noopener noreferrer" style={{ marginTop: '10px', display: 'block' }}>
                {msg.text}
              </a>
            )}
          </MediaContainer>
          <Timestamp $isUser={msg.isUser}>
            {new Date(msg.timestamp).toLocaleTimeString()}
          </Timestamp>
        </Message>
      ))}
      <div ref={messagesEndRef} />

      {isModalOpen && (
        <ModalOverlay onClick={closeModal}>
          <ModalContent onClick={(e) => e.stopPropagation()}>
            <ModalImage src={modalImageUrl} alt="Expanded Image" />
            <CloseButton onClick={closeModal} />
          </ModalContent>
        </ModalOverlay>
      )}
    </MessagesArea>
  );
}

export default MessageList;
