import React, { useState, useEffect } from 'react';
import cityService from '../../../../../../services/configuracoes_do_sistema/cityService';
import styles from './CitySettings.module.css';

const CitySettings = () => {
    const [cities, setCities] = useState([]);
    const [cidade, setCidade] = useState('');
    const [cep, setCep] = useState('');
    const [editIndex, setEditIndex] = useState(null);
    const [editCidade, setEditCidade] = useState('');
    const [editCep, setEditCep] = useState('');

    useEffect(() => {
        fetchCities();
    }, []);

    const fetchCities = async () => {
        try {
            const data = await cityService.getCities();
            setCities(data);
        } catch (error) {
            console.error('Erro ao buscar cidades:', error);
        }
    };

    const addCity = async () => {
        if (cidade && cep) {
            try {
                const newCity = await cityService.addCity(cidade, cep);
                setCities([...cities, newCity]);
                setCidade('');
                setCep('');
            } catch (error) {
                console.error('Erro ao adicionar cidade:', error);
            }
        }
    };

    const startEditCity = (index) => {
        setEditIndex(index);
        setEditCidade(cities[index].cidade);
        setEditCep(cities[index].cep);
    };

    const confirmEditCity = async (index) => {
        const updatedCity = { cidade: editCidade, cep: editCep };
        try {
            await cityService.updateCity(cities[index].id, editCidade, editCep);
            const newCities = cities.map((c, i) =>
                i === index ? { ...updatedCity, id: cities[index].id } : c
            );
            setCities(newCities);
            setEditIndex(null);
            setEditCidade('');
            setEditCep('');
        } catch (error) {
            console.error('Erro ao atualizar cidade:', error);
        }
    };

    const cancelEditCity = () => {
        setEditIndex(null);
        setEditCidade('');
        setEditCep('');
    };

    const handleKeyPress = (e, index) => {
        if (e.key === 'Enter') {
            confirmEditCity(index);
        } else if (e.key === 'Escape') {
            cancelEditCity();
        }
    };

    const deleteCity = async (index) => {
        try {
            await cityService.deleteCity(cities[index].id);
            const newCities = cities.filter((_, i) => i !== index);
            setCities(newCities);
        } catch (error) {
            console.error('Erro ao deletar cidade:', error);
        }
    };

    return (
        <div className={styles['city-settings-container']}>
            <h1 className={styles['city-settings-h1']}>Configuração de Cidades</h1>
            <div className={styles['city-settings-formGroup']}>
                <input
                    type="text"
                    value={cidade}
                    onChange={(e) => setCidade(e.target.value)}
                    placeholder="Cidade"
                    className={styles['city-settings-input']}
                />
                <input
                    type="text"
                    value={cep}
                    onChange={(e) => setCep(e.target.value)}
                    placeholder="CEP"
                    className={styles['city-settings-input']}
                />
                <button onClick={addCity} className={styles['city-settings-addButton']}>Adicionar</button>
            </div>
            <div className={styles['city-settings-cityList']}>
                {cities.map((city, index) => (
                    <div key={index} className={styles['city-settings-cityItem']}>
                        {editIndex === index ? (
                            <>
                                <input
                                    type="text"
                                    value={editCidade}
                                    onChange={(e) => setEditCidade(e.target.value)}
                                    onKeyDown={(e) => handleKeyPress(e, index)}
                                    className={styles['city-settings-cityInput']}
                                    autoFocus
                                />
                                <input
                                    type="text"
                                    value={editCep}
                                    onChange={(e) => setEditCep(e.target.value)}
                                    onKeyDown={(e) => handleKeyPress(e, index)}
                                    className={styles['city-settings-cityInput']}
                                />
                                <button onClick={() => confirmEditCity(index)} className={styles['city-settings-confirmButton']}>✔️</button>
                                <button onClick={cancelEditCity} className={styles['city-settings-cancelButton']}>✖️</button>
                            </>
                        ) : (
                            <>
                                <span>{city.cidade} - {city.cep}</span>
                                <button className={styles['city-settings-editButton']} onClick={() => startEditCity(index)}>✏️</button>
                            </>
                        )}
                        <button className={styles['city-settings-deleteButton']} onClick={() => deleteCity(index)}>🗑️</button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CitySettings;