import React, { useState, useEffect } from 'react';
import { Modal, Button, Row, Alert, Spinner } from 'react-bootstrap';
import { fetchChatbotStatus } from '../../../../services/chatbotService';
import QRCode from 'qrcode.react';

const QRCodeUpdateModal = ({ show, handleClose }) => {
  const [status, setStatus] = useState('loading'); // loading, connected, error
  const [qrCode, setQrCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (show) {
      const getStatus = async () => {
        try {
          const data = await fetchChatbotStatus();
          if (data.connected) {
            setStatus('connected');
          } else {
            setQrCode(data.qrCode);
            setStatus('disconnected');
          }
        } catch (error) {
          setErrorMessage('Erro ao buscar status do chatbot. Tente novamente mais tarde.');
          setStatus('error');
        }
      };

      getStatus();
    }
  }, [show]);

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Status do Chatbot</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {status === 'loading' && (
          <Row className="justify-content-center">
            <Spinner animation="border" variant="primary" />
          </Row>
        )}
        {status === 'connected' && (
          <Alert variant="success" className="text-center">
            Chatbot conectado com sucesso!
          </Alert>
        )}
        {status === 'disconnected' && (
          <>
            <Alert variant="warning" className="text-center">
              Chatbot desconectado. Escaneie o QR code abaixo para conectar.
            </Alert>
            <Row className="justify-content-center">
              <QRCode value={qrCode} size={256} />
            </Row>
          </>
        )}
        {status === 'error' && (
          <Alert variant="danger" className="text-center">
            {errorMessage}
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default QRCodeUpdateModal;
