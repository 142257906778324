import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'aos/dist/aos.css';
import AOS from 'aos';
import styles from './Aplicativos.module.css';
import Navbar from '../../Common/Navbar'; // Supondo que você tenha um componente Navbar
import Footer from '../../Common/Footer';

const Aplicativos = () => {
  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);

  return (
    <div className={styles.aplicativosContainer}>
      <Navbar />

      <div className={styles.content}>
        <div className={styles.container}>
          <div className={styles.configBlock} data-aos="fade-up">
            <a href="/aplicativos/postagem-olx">
              <div className={styles.configHeader}>Postagem OLX</div>
              <div className={styles.configContent}>
                <p>Este aplicativo automatiza a criação e postagem de anúncios na OLX, permitindo que você gerencie seus anúncios de forma eficiente e sem esforço manual.</p>
              </div>
            </a>
          </div>

          <div className={styles.configBlock} data-aos="fade-up" data-aos-delay="100">
            <a href="/aplicativos/postagem-instagram">
              <div className={styles.configHeader}>Postagem Instagram</div>
              <div className={styles.configContent}>
                <p>Este aplicativo facilita a automação da postagem de conteúdo de marketing no Instagram, ajudando você a manter seu feed atualizado com novos conteúdos de forma programática.</p>
              </div>
            </a>
          </div>

          <div className={styles.configBlock} data-aos="fade-up" data-aos-delay="200">
            <a href="/aplicativos/chatpgt">
              <div className={styles.configHeader}>ChatGPT</div>
              <div className={styles.configContent}>
                <p>Conteúdo de exemplo para a Configuração 3. Aqui você pode adicionar qualquer informação
                  relevante ou instruções específicas.</p>
              </div>
            </a>
          </div>

          <div className={styles.configBlock} data-aos="fade-up" data-aos-delay="300">
            <a href="config4.html">
              <div className={styles.configHeader}>Feature Futura</div>
              <div className={styles.configContent}>
                <p>Conteúdo de exemplo para a Configuração 4. Aqui você pode adicionar qualquer informação
                  relevante ou instruções específicas.</p>
              </div>
            </a>
          </div>
        </div>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Aplicativos;
