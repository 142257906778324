import React from 'react';
import { Card } from 'react-bootstrap';
import './ChatMessage.css';

const ChatMessage = ({ message }) => (
  <div className={`chat-message ${message.user === 'Me' ? 'my-message' : 'other-message'}`}>
    <Card>
      <Card.Body>
        <Card.Text><strong>{message.user}: </strong>{message.text}</Card.Text>
      </Card.Body>
    </Card>
  </div>
);

export default ChatMessage;
