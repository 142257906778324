import api from './api';

export const getProducts = async () => {
  const response = await api.get('/produtos');
  return response.data;
};

export const createProduct = async (files) => {
  const formData = new FormData();
  if (files) {
    for (const file of files) {
      formData.append('imagens', file);
    }
  }

  const response = await api.post('/produtos', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return response.data;
};

export const updateProduct = async (id, productData) => {
  const response = await api.put(`/produtos/${id}`, productData);
  return response.data;
};

export const deleteProduct = async (productId) => {
  try {
    const response = await api.delete(`/produtos/${productId}`);
    return response.data; // ou apenas retornar se for necessário
  } catch (error) {
    throw new Error(`Erro ao deletar produto ${productId}: ${error.message}`);
  }
};