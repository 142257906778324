import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, ListGroup, InputGroup } from 'react-bootstrap';
import { FaEdit, FaTrash, FaPlus } from 'react-icons/fa';
import { getCategories, addCategory, updateCategory, deleteCategory } from '../../../services/categoryService';
import './CategoriasContainer.css';

function CategoriesContainer({ show, handleClose }) {
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState('');
  const [editCategory, setEditCategory] = useState(null);
  const [editCategoryName, setEditCategoryName] = useState('');

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const data = await getCategories();
        setCategories(Array.isArray(data) ? data : []);
      } catch (error) {
        console.error("Erro ao buscar categorias:", error);
        setCategories([]);
      }
    };
    fetchCategories();
  }, []);

  const handleAddCategory = async () => {
    try {
      await addCategory(newCategory);
      const data = await getCategories();
      setCategories(Array.isArray(data) ? data : []);
      setNewCategory('');
    } catch (error) {
      console.error("Erro ao adicionar categoria:", error);
    }
  };

  const handleEditCategory = async (id, name) => {
    try {
      await updateCategory(id, { name });
      const data = await getCategories();
      setCategories(Array.isArray(data) ? data : []);
      setEditCategory(null);
    } catch (error) {
      console.error("Erro ao editar categoria:", error);
    }
  };

  const handleDeleteCategory = async (id) => {
    try {
      await deleteCategory(id);
      const data = await getCategories();
      setCategories(Array.isArray(data) ? data : []);
    } catch (error) {
      console.error("Erro ao remover categoria:", error);
    }
  };

  const handleKeyPress = async (event, id) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (id) {
        await handleEditCategory(id, editCategoryName);
      } else {
        await handleAddCategory();
      }
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton className="categoriasContainerProdutos-modal-header">
        <Modal.Title className="categoriasContainerProdutos-modal-title">Gerenciar Categorias</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <InputGroup className="categoriasContainerProdutos-input-group">
              <Form.Control
                type="text"
                placeholder="Nome da nova categoria"
                value={newCategory}
                onChange={(e) => setNewCategory(e.target.value)}
                onKeyPress={(e) => handleKeyPress(e)}
              />
              <Button variant="primary" onClick={handleAddCategory} className="categoriasContainerProdutos-btn-primary">
                <FaPlus /> Adicionar
              </Button>
            </InputGroup>
          </Form.Group>
        </Form>
        <hr />
        <h5>Categorias Existentes</h5>
        <ListGroup>
          {categories.map(category => (
            <ListGroup.Item key={category.id} className="d-flex justify-content-between align-items-center categoriasContainerProdutos-list-group-item">
              {editCategory === category.id ? (
                <InputGroup className="categoriasContainerProdutos-input-group">
                  <Form.Control
                    type="text"
                    value={editCategoryName}
                    onChange={(e) => setEditCategoryName(e.target.value)}
                    onKeyPress={(e) => handleKeyPress(e, category.id)}
                  />
                  <Button variant="primary" onClick={() => handleEditCategory(category.id, editCategoryName)} className="categoriasContainerProdutos-btn-primary">
                    Salvar
                  </Button>
                </InputGroup>
              ) : (
                <span>{category.name}</span>
              )}
              <div>
                <Button variant="outline-success" size="sm" onClick={() => {
                  setEditCategory(category.id);
                  setEditCategoryName(category.name);
                }}>
                  <FaEdit />
                </Button>{' '}
                <Button variant="outline-danger" size="sm" onClick={() => handleDeleteCategory(category.id)}>
                  <FaTrash />
                </Button>
              </div>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Modal.Body>
    </Modal>
  );
}

export default CategoriesContainer;
