import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, ListGroup, InputGroup, Collapse, Alert, Card } from 'react-bootstrap';
import { FaEdit, FaTrash, FaPlus, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { getCategories, addCategory, updateCategory, deleteCategory, addSubcategory, deleteSubcategory, updateSubcategory, addType, deleteType, updateType } from '../../../../services/categoryOlxService';
import styles from './CategoriasContainerOLX.module.css';

function CategoriesContainer({ show, handleClose }) {
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState('');
  const [newSubcategory, setNewSubcategory] = useState('');
  const [newType, setNewType] = useState('');
  const [editCategory, setEditCategory] = useState(null);
  const [editCategoryName, setEditCategoryName] = useState('');
  const [editSubcategory, setEditSubcategory] = useState(null);
  const [editSubcategoryName, setEditSubcategoryName] = useState('');
  const [editType, setEditType] = useState(null);
  const [editTypeName, setEditTypeName] = useState('');
  const [expandedCategory, setExpandedCategory] = useState(null);
  const [expandedSubcategory, setExpandedSubcategory] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const data = await getCategories();
        setCategories(Array.isArray(data) ? data : []);
      } catch (error) {
        console.error("Erro ao buscar categorias:", error);
        setCategories([]);
      }
    };
    fetchCategories();
  }, []);

  const handleAddCategory = async () => {
    if (!newCategory.trim()) {
      setError('Nome da categoria não pode estar vazio');
      return;
    }
    try {
      await addCategory(newCategory);
      const data = await getCategories();
      setCategories(Array.isArray(data) ? data : []);
      setNewCategory('');
      setError('');
    } catch (error) {
      console.error("Erro ao adicionar categoria:", error);
    }
  };

  const handleAddSubcategory = async (categoryId) => {
    if (!newSubcategory.trim()) {
      setError('Nome da subcategoria não pode estar vazio');
      return;
    }
    try {
      await addSubcategory(categoryId, newSubcategory);
      const data = await getCategories();
      setCategories(Array.isArray(data) ? data : []);
      setNewSubcategory('');
      setError('');
    } catch (error) {
      console.error("Erro ao adicionar subcategoria:", error);
    }
  };

  const handleAddType = async (subcategoryId) => {
    if (!newType.trim()) {
      setError('Nome do tipo não pode estar vazio');
      return;
    }
    try {
      await addType(subcategoryId, newType);
      const data = await getCategories();
      setCategories(Array.isArray(data) ? data : []);
      setNewType('');
      setError('');
    } catch (error) {
      console.error("Erro ao adicionar tipo:", error);
    }
  };

  const handleEditCategory = async (id, name) => {
    if (!name.trim()) {
      setError('Nome da categoria não pode estar vazio');
      return;
    }
    try {
      await updateCategory(id, { name });
      const data = await getCategories();
      setCategories(Array.isArray(data) ? data : []);
      setEditCategory(null);
      setError('');
    } catch (error) {
      console.error("Erro ao editar categoria:", error);
    }
  };

  const handleEditSubcategory = async (subcategoryId, name) => {
    if (!name.trim()) {
      setError('Nome da subcategoria não pode estar vazio');
      return;
    }
    try {
      await updateSubcategory(subcategoryId, { name });
      const data = await getCategories();
      setCategories(Array.isArray(data) ? data : []);
      setEditSubcategory(null);
      setError('');
    } catch (error) {
      console.error("Erro ao editar subcategoria:", error);
    }
  };

  const handleEditType = async (typeId, name) => {
    if (!name.trim()) {
      setError('Nome do tipo não pode estar vazio');
      return;
    }
    try {
      await updateType(typeId, { name });
      const data = await getCategories();
      setCategories(Array.isArray(data) ? data : []);
      setEditType(null);
      setError('');
    } catch (error) {
      console.error("Erro ao editar tipo:", error);
    }
  };

  const handleDeleteCategory = async (id) => {
    try {
      await deleteCategory(id);
      const data = await getCategories();
      setCategories(Array.isArray(data) ? data : []);
    } catch (error) {
      console.error("Erro ao remover categoria:", error);
    }
  };

  const handleDeleteSubcategory = async (subcategoryId) => {
    try {
      await deleteSubcategory(subcategoryId);
      const data = await getCategories();
      setCategories(Array.isArray(data) ? data : []);
    } catch (error) {
      console.error("Erro ao remover subcategoria:", error);
    }
  };

  const handleDeleteType = async (typeId) => {
    try {
      await deleteType(typeId);
      const data = await getCategories();
      setCategories(Array.isArray(data) ? data : []);
    } catch (error) {
      console.error("Erro ao remover tipo:", error);
    }
  };

  const handleKeyPress = async (event, id, isSubcategory = false, subcategoryId = null, isType = false, typeId = null) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (isType) {
        if (typeId) {
          await handleEditType(typeId, editTypeName);
        } else {
          await handleAddType(subcategoryId);
        }
      } else if (isSubcategory) {
        if (subcategoryId) {
          await handleEditSubcategory(subcategoryId, editSubcategoryName);
        } else {
          await handleAddSubcategory(id);
        }
      } else {
        if (id) {
          await handleEditCategory(id, editCategoryName);
        } else {
          await handleAddCategory();
        }
      }
    }
  };

  const toggleCategory = (id) => {
    setExpandedCategory(expandedCategory === id ? null : id);
  };

  const toggleSubcategory = (id) => {
    setExpandedSubcategory(expandedSubcategory === id ? null : id);
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header className={styles['categoriascontainer-modal-header']} closeButton>
        <Modal.Title className={styles['categoriascontainer-modal-title']}>Gerenciar Categorias</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <Alert variant="danger">{error}</Alert>}
        <Form>
          <Form.Group className="mb-3">
            <InputGroup className={styles['categoriascontainer-input-group']}>
              <Form.Control
                type="text"
                placeholder="Nome da nova categoria"
                value={newCategory}
                onChange={(e) => setNewCategory(e.target.value)}
                onKeyPress={(e) => handleKeyPress(e)}
              />
              <Button
                variant="primary"
                onClick={handleAddCategory}
                className={styles['categoriascontainer-btn-primary']}
              >
                <FaPlus />
              </Button>
            </InputGroup>
          </Form.Group>
        </Form>
        <ListGroup>
          {categories.map((category) => (
            <ListGroup.Item
              key={category.id}
              className={styles['categoriascontainer-list-group-item']}
            >
              <Card>
                <Card.Header
                  onClick={() => toggleCategory(category.id)}
                  className={styles['categoriascontainer-card-header']}
                >
                  <span>
                    {editCategory === category.id ? (
                      <Form.Control
                        type="text"
                        value={editCategoryName}
                        onChange={(e) => setEditCategoryName(e.target.value)}
                        onKeyPress={(e) => handleKeyPress(e, category.id)}
                      />
                    ) : (
                      category.name
                    )}
                  </span>
                  <span>
                    <FaEdit
                      className={styles['categoriascontainer-ml-3']}
                      onClick={() => {
                        setEditCategory(category.id);
                        setEditCategoryName(category.name);
                      }}
                    />
                    <FaTrash
                      className={styles['categoriascontainer-ml-3']}
                      onClick={() => handleDeleteCategory(category.id)}
                    />
                    {expandedCategory === category.id ? <FaChevronUp /> : <FaChevronDown />}
                  </span>
                </Card.Header>
                <Collapse in={expandedCategory === category.id}>
                  <Card.Body className={styles['categoriascontainer-card-body']}>
                    <Form>
                      <Form.Group className="mb-3">
                        <InputGroup className={styles['categoriascontainer-input-group']}>
                          <Form.Control
                            type="text"
                            placeholder="Nome da nova subcategoria"
                            value={newSubcategory}
                            onChange={(e) => setNewSubcategory(e.target.value)}
                            onKeyPress={(e) => handleKeyPress(e, category.id, true)}
                          />
                          <Button
                            variant="primary"
                            onClick={() => handleAddSubcategory(category.id)}
                            className={styles['categoriascontainer-btn-primary']}
                          >
                            <FaPlus />
                          </Button>
                        </InputGroup>
                      </Form.Group>
                    </Form>
                    <ListGroup>
                      {category.subcategories.map((subcategory) => (
                        <ListGroup.Item
                          key={subcategory.id}
                          className={styles['categoriascontainer-list-group-item']}
                        >
                          <Card>
                            <Card.Header
                              onClick={() => toggleSubcategory(subcategory.id)}
                              className={styles['categoriascontainer-card-header']}
                            >
                              <span>
                                {editSubcategory === subcategory.id ? (
                                  <Form.Control
                                    type="text"
                                    value={editSubcategoryName}
                                    onChange={(e) => setEditSubcategoryName(e.target.value)}
                                    onKeyPress={(e) =>
                                      handleKeyPress(e, category.id, true, subcategory.id)
                                    }
                                  />
                                ) : (
                                  subcategory.name
                                )}
                              </span>
                              <span>
                                <FaEdit
                                  className={styles['categoriascontainer-ml-3']}
                                  onClick={() => {
                                    setEditSubcategory(subcategory.id);
                                    setEditSubcategoryName(subcategory.name);
                                  }}
                                />
                                <FaTrash
                                  className={styles['categoriascontainer-ml-3']}
                                  onClick={() => handleDeleteSubcategory(subcategory.id)}
                                />
                                {expandedSubcategory === subcategory.id ? (
                                  <FaChevronUp />
                                ) : (
                                  <FaChevronDown />
                                )}
                              </span>
                            </Card.Header>
                            <Collapse in={expandedSubcategory === subcategory.id}>
                              <Card.Body className={styles['categoriascontainer-card-body']}>
                                <Form>
                                  <Form.Group className="mb-3">
                                    <InputGroup className={styles['categoriascontainer-input-group']}>
                                      <Form.Control
                                        type="text"
                                        placeholder="Nome do novo tipo"
                                        value={newType}
                                        onChange={(e) => setNewType(e.target.value)}
                                        onKeyPress={(e) =>
                                          handleKeyPress(e, category.id, true, subcategory.id, true)
                                        }
                                      />
                                      <Button
                                        variant="primary"
                                        onClick={() => handleAddType(subcategory.id)}
                                        className={styles['categoriascontainer-btn-primary']}
                                      >
                                        <FaPlus />
                                      </Button>
                                    </InputGroup>
                                  </Form.Group>
                                </Form>
                                <ListGroup>
                                  {subcategory.types.map((type) => (
                                    <ListGroup.Item
                                      key={type.id}
                                      className={styles['categoriascontainer-type-item']}
                                    >
                                      <span>
                                        {editType === type.id ? (
                                          <Form.Control
                                            type="text"
                                            value={editTypeName}
                                            onChange={(e) => setEditTypeName(e.target.value)}
                                            onKeyPress={(e) =>
                                              handleKeyPress(
                                                e,
                                                category.id,
                                                true,
                                                subcategory.id,
                                                true,
                                                type.id
                                              )
                                            }
                                          />
                                        ) : (
                                          type.name
                                        )}
                                      </span>
                                      <span>
                                        <FaEdit
                                          className={styles['categoriascontainer-ml-3']}
                                          onClick={() => {
                                            setEditType(type.id);
                                            setEditTypeName(type.name);
                                          }}
                                        />
                                        <FaTrash
                                          className={styles['categoriascontainer-ml-3']}
                                          onClick={() => handleDeleteType(type.id)}
                                        />
                                      </span>
                                    </ListGroup.Item>
                                  ))}
                                </ListGroup>
                              </Card.Body>
                            </Collapse>
                          </Card>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  </Card.Body>
                </Collapse>
              </Card>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CategoriesContainer;
