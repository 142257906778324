// services/estoqueService.js
import axios from 'axios';
import { serverUrl } from './api';

export const getAllEstoques = async () => {
    try {
        const response = await axios.get(`${serverUrl}/api/estoque`);
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar estoques:', error);
        throw error;
    }
};

export const getEstoqueAtual = async (produtoId) => {
    try {
        const response = await axios.get(`${serverUrl}/api/estoque/${produtoId}`);
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar estoque atual:', error);
        throw error;
    }
};
