import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import './ImageModal.css';

const ImageModal = ({
  show = false,
  onHide = () => { },
  onUpload = () => { },
  produtoId
}) => {
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = () => {
    if (file) {
      onUpload(file, produtoId); // Passando o produtoId junto com o arquivo
      setFile(null);
      onHide();
    }
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Enviar Imagens do Produto</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="upload-section">
          <Form.Control type="file" onChange={handleFileChange} className="form-control-file" />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleSubmit}>
          Confirmar e Enviar
        </Button>
        <Button variant="secondary" onClick={onHide}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};


export default ImageModal;
