import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'aos/dist/aos.css';
import AOS from 'aos';
import styles from './ConfigCard.module.css';
import Navbar from '../Navbar';
import Footer from '../Footer';

const ConfigCard = ({ cards }) => {
    const navigate = useNavigate();
    const [detailActive, setDetailActive] = useState(false);
    const [selectedCard, setSelectedCard] = useState('');

    useEffect(() => {
        AOS.init({
            duration: 1200,
        });
    }, []);

    const openDetail = (card) => {
        if (card.route) {
            navigate(card.route);
        } else {
            setSelectedCard(card.title);
            setDetailActive(true);
        }
    };

    const closeDetail = () => {
        setDetailActive(false);
        setSelectedCard('');
    };

    const getDetailContent = () => {
        const card = cards.find((card) => card.title === selectedCard);
        return card ? card.detailComponent : <p>Details about the selected configuration will appear here.</p>;
    };

    return (
        <div className={styles.appWrapper}>
            <Navbar />
            <div className={styles.appBackground}>
                <div className={styles.appContainer}>
                    <div className={styles.container}>
                        {cards.map((card, index) => (
                            <div
                                key={index}
                                className={styles.card}
                                data-aos="fade-up"
                                data-aos-delay={index * 100}
                                onClick={() => openDetail(card)}
                                style={{ cursor: 'pointer' }}
                            >
                                <div className={styles.cardContent}>{card.title}</div>
                            </div>
                        ))}
                    </div>
                </div>

                {detailActive && (
                    <div id="cardDetail" className={`${styles.cardDetail} ${styles.active}`}>
                        <button className={styles.closeBtn} onClick={closeDetail}>Close</button>
                        <h2>{selectedCard}</h2>
                        {getDetailContent()}
                    </div>
                )}
            </div>
            <Footer />
        </div>
    );
};

export default ConfigCard;
