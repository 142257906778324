import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import './DateModal.css'; // Import the custom CSS for the modal

registerLocale('pt-BR', ptBR);

function DateModal({ show, handleClose, selectedDate, handleDateChange }) {
    const [startDate, setStartDate] = useState(new Date());

    useEffect(() => {
        if (selectedDate && !isNaN(new Date(selectedDate))) {
            setStartDate(new Date(selectedDate));
        } else {
            setStartDate(new Date());
        }
    }, [selectedDate, show]);

    return (
        <Modal show={show} onHide={handleClose} dialogClassName="custom-modal" centered>
            <Modal.Header closeButton>
                <Modal.Title>Selecionar Data</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <DatePicker
                    selected={startDate}
                    onChange={(date) => {
                        setStartDate(date);
                        handleDateChange(date);
                    }}
                    dateFormat="dd/MM/yyyy"
                    className="form-control datepicker"
                    locale="pt-BR"
                    inline
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose} className="ml-auto">
                    Fechar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default DateModal;
