import React from 'react';
import styles from './Home.module.css';
import { Helmet } from 'react-helmet';
import Navbar from '../../Common/Navbar';
import Sidebar from '../../Common/Sidebar';
import Footer from '../../Common/Footer';

function Home() {
  return (
    <div>
      <Helmet>
        <title>Sparkfluxe Home | ADM</title>
      </Helmet>
      {/* Navbar */}
      <Navbar />
      {/* Sidebar */}
      <Sidebar />

      <div className={styles.content}>
        <h1>Página Inicial</h1>
        <div className={`card ${styles.card}`}>
          <div className="card-header bg-primary text-white">
            <i className="fas fa-chart-line"></i>
            <span>Visão Geral</span>
          </div>
          <div className="card-body">
            <p>Bem-vindo ao sistema de administração da SparkFluxe. Use o menu ao lado para navegar pelas diferentes
              seções do sistema.</p>
          </div>
        </div>
        <div className={`card ${styles.card}`}>
          <div className="card-header bg-info text-white">
            <i className="fas fa-box"></i>
            <span>Pedidos Recentes</span>
          </div>
          <div className="card-body">
            <ul>
              <li>#1 21/05/2025 08h30m</li>
              <li>#2 23/05/2025 17h20m</li>
              <li>#3 25/05/2025 14h30m</li>
            </ul>
          </div>
        </div>
        <div className={`card ${styles.card}`}>
          <div className="card-header bg-success text-white">
            <i className="fas fa-users"></i>
            <span>Novos Clientes</span>
          </div>
          <div className="card-body">
            <ul>
              <li>Renata Lima dos Santos | R$: 45,00</li>
              <li>Larissa Gonçalves Odinélia | R$: 75,00</li>
              <li>Gustavo Pereira da Silva | R$: 38,00</li>
            </ul>
          </div>
        </div>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default Home;
