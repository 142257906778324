import api from './api';

const API_URL = '/categoriesolx';

export const getCategories = async () => {
  const response = await api.get(API_URL);
  return response.data;
};

export const addCategory = async (name) => {
  const response = await api.post(API_URL, { name });
  return response.data;
};

export const updateCategory = async (id, updates) => {
  const response = await api.put(`${API_URL}/${id}`, updates);
  return response.data;
};

export const deleteCategory = async (id) => {
  const response = await api.delete(`${API_URL}/${id}`);
  return response.data;
};

export const addSubcategory = async (categoryId, name) => {
  const response = await api.post(`${API_URL}/${categoryId}/subcategories`, { name });
  return response.data;
};

export const deleteSubcategory = async (subcategoryId) => {
  const response = await api.delete(`${API_URL}/subcategories/${subcategoryId}`);
  return response.data;
};

export const updateSubcategory = async (subcategoryId, updates) => {
  const response = await api.put(`${API_URL}/subcategories/${subcategoryId}`, updates);
  return response.data;
};

export const addType = async (subcategoryId, name) => {
  const response = await api.post(`${API_URL}/subcategories/${subcategoryId}/types`, { name });
  return response.data;
};

export const deleteType = async (typeId) => {
  const response = await api.delete(`${API_URL}/types/${typeId}`);
  return response.data;
};

export const updateType = async (typeId, updates) => {
  const response = await api.put(`${API_URL}/subcategories/types/${typeId}`, updates);
  return response.data;
};
