import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'aos/dist/aos.css';
import AOS from 'aos';
import styles from './Configuracoes.module.css';
import Navbar from '../../Common/Navbar';
import Footer from '../../Common/Footer';
import QRCodeUpdateModal from './cards/QRCodeUpdateModal/QRCodeUpdateModal'; // Importando o modal

const Configuracoes = () => {
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);

  const handleModalShow = () => setModalShow(true);
  const handleModalClose = () => setModalShow(false);

  return (
    <div className={styles.configuracoesContainer}>
      <Navbar />

      <div className={styles.content}>
        <div className={styles.container}>
          <div className={styles.configBlock} data-aos="fade-up">
            <div className={styles.configHeader}>Editar Mensagens</div>
            <div className={styles.configContent}>
              <p>Editar as mensagens padrões do ChatBOT, permitindo uma melhor personalização.</p>
            </div>
          </div>

          <div
            className={styles.configBlock}
            data-aos="fade-up"
            data-aos-delay="100"
            onClick={handleModalShow} // Adicionando o evento onClick
            style={{ cursor: 'pointer' }}
          >
            <div className={styles.configHeader}>Atualizar QR Code</div>
            <div className={styles.configContent}>
              <p>Cadastrar o WhatsApp no sistema via QR CODE.</p>
            </div>
          </div>

          <div className={styles.configBlock} data-aos="fade-up" data-aos-delay="200">
            <a href="/configuracoes/configuracoes_do_sistema">
              <div className={styles.configHeader}>Configurações do Sistema</div>
              <div className={styles.configContent}>
                <p>Alterar informações importantes para o funcionamento do sistema, a exemplo das cidades de atuação, formas de pagamento e etecetera.</p>
              </div>
            </a>
          </div>

          <div className={styles.configBlock} data-aos="fade-up" data-aos-delay="300">
            <div className={styles.configHeader}>Feature Futura</div>
            <div className={styles.configContent}>
              <p>Conteúdo de exemplo para a Configuração 4. Aqui você pode adicionar qualquer informação
                relevante ou instruções específicas.</p>
            </div>
          </div>
        </div>
      </div>

      <Footer />

      <QRCodeUpdateModal show={modalShow} handleClose={handleModalClose} />
    </div>
  );
};

export default Configuracoes;
