import React, { useState, useEffect } from 'react';
import { Modal, Form, Button, Row, Col } from 'react-bootstrap';
import { getCategories } from '../../../../services/categoryOlxService';
import ImageUpload from './ImageUpload';
import './AnuncioForm.css';
import { FaExclamationCircle } from 'react-icons/fa';

const AnuncioForm = ({ show, onHide, anuncio, setAnuncio, handleSave }) => {
    const [categories, setCategories] = useState([]);
    const [subcategories, setSubcategories] = useState([]);
    const [types, setTypes] = useState([]);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const data = await getCategories();
                setCategories(Array.isArray(data) ? data : []);

                if (anuncio.categoria) {
                    const selectedCategoryObj = data.find(category => category.name === anuncio.categoria);
                    setSubcategories(selectedCategoryObj ? selectedCategoryObj.subcategories : []);

                    if (anuncio.subcategoria) {
                        const selectedSubcategoryObj = selectedCategoryObj.subcategories.find(subcategory => subcategory.name === anuncio.subcategoria);
                        setTypes(selectedSubcategoryObj ? selectedSubcategoryObj.types : []);
                    }
                }
            } catch (error) {
                console.error("Erro ao buscar categorias:", error);
            }
        };
        fetchCategories();
    }, [anuncio.categoria, anuncio.subcategoria]);

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSave(anuncio);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setAnuncio(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleCategoryChange = (e) => {
        const selectedCategory = e.target.value;
        const selectedCategoryObj = categories.find(category => category.name === selectedCategory);

        setAnuncio(prevState => ({
            ...prevState,
            categoria: selectedCategory,
            subcategoria: selectedCategoryObj && selectedCategoryObj.subcategories.length ? prevState.subcategoria : '',
            tipo: ''
        }));

        setSubcategories(selectedCategoryObj ? selectedCategoryObj.subcategories : []);
        setTypes([]);
    };

    const handleSubcategoryChange = (e) => {
        const selectedSubcategory = e.target.value;
        const selectedSubcategoryObj = subcategories.find(subcategory => subcategory.name === selectedSubcategory);

        setAnuncio(prevState => ({
            ...prevState,
            subcategoria: selectedSubcategory,
            tipo: selectedSubcategoryObj && selectedSubcategoryObj.types.length ? prevState.tipo : ''
        }));

        setTypes(selectedSubcategoryObj ? selectedSubcategoryObj.types : []);
    };

    const isFieldEmpty = (field) => !anuncio[field];

    return (
        <Modal show={show} onHide={onHide} centered className="custom-modal">
            <Modal.Header closeButton>
                <Modal.Title>{anuncio.id ? 'Editar Anúncio' : 'Adicionar Anúncio'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onKeyPress={handleKeyPress}>
                    <Row>
                        <Col md={6}>
                            <Form.Group className="position-relative">
                                <Form.Label>Título</Form.Label>
                                <Form.Control
                                    name="titulo"
                                    placeholder="Título"
                                    value={anuncio.titulo || ''}
                                    onChange={handleChange}
                                    className={isFieldEmpty('titulo') ? 'not-filled' : ''}
                                />
                                {isFieldEmpty('titulo') && <FaExclamationCircle className="not-filled-icon" />}
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="position-relative">
                                <Form.Label>Descrição</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="descricao"
                                    placeholder="Descrição"
                                    value={anuncio.descricao || ''}
                                    onChange={handleChange}
                                    className={isFieldEmpty('descricao') ? 'not-filled' : ''}
                                />
                                {isFieldEmpty('descricao') && <FaExclamationCircle className="not-filled-icon" />}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Form.Group className="position-relative">
                                <Form.Label>Categoria</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="categoria"
                                    value={anuncio.categoria || ''}
                                    onChange={handleCategoryChange}
                                    className={isFieldEmpty('categoria') ? 'not-filled' : ''}
                                >
                                    <option value="">Selecione uma categoria</option>
                                    {categories.map(category => (
                                        <option key={category.id} value={category.name}>
                                            {category.name}
                                        </option>
                                    ))}
                                </Form.Control>
                                {isFieldEmpty('categoria') && <FaExclamationCircle className="not-filled-icon" />}
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="position-relative">
                                <Form.Label>Subcategoria</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="subcategoria"
                                    value={anuncio.subcategoria || ''}
                                    onChange={handleSubcategoryChange}
                                    disabled={!subcategories.length}
                                    className={isFieldEmpty('subcategoria') ? 'not-filled' : ''}
                                >
                                    <option value="">Selecione uma subcategoria</option>
                                    {subcategories.map(subcategory => (
                                        <option key={subcategory.id} value={subcategory.name}>
                                            {subcategory.name}
                                        </option>
                                    ))}
                                </Form.Control>
                                {isFieldEmpty('subcategoria') && <FaExclamationCircle className="not-filled-icon" />}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Form.Group className="position-relative">
                                <Form.Label>Tipo</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="tipo"
                                    value={anuncio.tipo || ''}
                                    onChange={handleChange}
                                    disabled={!types.length}
                                    className={isFieldEmpty('tipo') ? 'not-filled' : ''}
                                >
                                    <option value="">Selecione um tipo</option>
                                    {types.map(type => (
                                        <option key={type.id} value={type.name}>
                                            {type.name}
                                        </option>
                                    ))}
                                </Form.Control>
                                {isFieldEmpty('tipo') && <FaExclamationCircle className="not-filled-icon" />}
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="position-relative">
                                <Form.Label>Condição</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="condicao"
                                    value={anuncio.condicao || ''}
                                    onChange={handleChange}
                                    className={isFieldEmpty('condicao') ? 'not-filled' : ''}
                                >
                                    <option value="">Selecione uma condição</option>
                                    <option value="Novo">Novo</option>
                                    <option value="Usado">Usado</option>
                                </Form.Control>
                                {isFieldEmpty('condicao') && <FaExclamationCircle className="not-filled-icon" />}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <ImageUpload anuncio={anuncio} setAnuncio={setAnuncio} />
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Cancelar
                </Button>
                <Button variant="primary" onClick={() => handleSave(anuncio)}>
                    {anuncio.id ? 'Salvar' : 'Adicionar'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AnuncioForm;
