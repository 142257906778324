import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';

const Header = ({ version }) => (
  <Navbar bg="dark" variant="dark">
    <Container>
      <Navbar.Brand href="#home">ChatGPT Interface</Navbar.Brand>
      <Navbar.Text>Versão: {version}</Navbar.Text>
    </Container>
  </Navbar>
);

export default Header;
