import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useDrag, useDrop } from 'react-dnd';
import { addImages, removeImage, updateImagesOrder } from '../../../../services/anuncioService';
import { serverUrl } from '../../../../services/api';
import './ImageUpload.css';

const ImageUpload = ({ anuncio, setAnuncio }) => {
    const [images, setImages] = useState([]);

    useEffect(() => {
        if (anuncio && anuncio.imagens) {
            setImages(anuncio.imagens.map((img, index) => ({ id: index.toString(), url: typeof img === 'string' ? img : img.file })));
        }
    }, [anuncio]);

    const handleImageChange = async (e) => {
        const files = Array.from(e.target.files);
        if (files.length > 0) {
            try {
                const updatedAnuncio = await addImages(anuncio.id, files);

                // Preservar a imagem do produto
                const updatedProduto = anuncio.Produto
                    ? { ...anuncio.Produto, imagem: anuncio.Produto.imagem }
                    : { nome: '', imagem: '' };

                setImages(updatedAnuncio.imagens.map((img, index) => ({ id: index.toString(), url: typeof img === 'string' ? img : img.file })));
                setAnuncio({
                    ...anuncio,
                    ...updatedAnuncio,
                    Produto: updatedProduto
                });
            } catch (error) {
                console.error('Erro ao adicionar imagens:', error);
            }
        }
    };


    const handleRemoveImage = async (index) => {
        try {
            const updatedAnuncio = await removeImage(anuncio.id, index);

            // Preservar a imagem do produto
            const updatedProduto = anuncio.Produto
                ? { ...anuncio.Produto, imagem: anuncio.Produto.imagem }
                : { nome: '', imagem: '' };

            setImages(updatedAnuncio.imagens.map((img, index) => ({ id: index.toString(), url: typeof img === 'string' ? img : img.file })));
            setAnuncio({
                ...anuncio,
                ...updatedAnuncio,
                Produto: updatedProduto
            });
        } catch (error) {
            console.error('Erro ao remover imagem:', error);
        }
    };


    const moveImage = (dragIndex, hoverIndex) => {
        const draggedImage = images[dragIndex];
        const updatedImages = [...images];
        updatedImages.splice(dragIndex, 1);
        updatedImages.splice(hoverIndex, 0, draggedImage);
        setImages(updatedImages);
    };

    const handleSaveOrder = async () => {
        try {
            const reorderedImages = images.map(image => image.url);
            const updatedAnuncio = await updateImagesOrder(anuncio.id, reorderedImages);

            // Preservar a imagem do produto
            const updatedProduto = anuncio.Produto
                ? { ...anuncio.Produto, imagem: anuncio.Produto.imagem }
                : { nome: '', imagem: '' };

            setAnuncio({
                ...anuncio,
                ...updatedAnuncio,
                Produto: updatedProduto
            });
        } catch (error) {
            console.error('Erro ao salvar a ordem das imagens:', error);
        }
    };


    const Image = ({ image, index }) => {
        const [, ref] = useDrag({
            type: 'image',
            item: { index },
        });

        const [, drop] = useDrop({
            accept: 'image',
            hover: (item) => {
                if (item.index !== index) {
                    moveImage(item.index, index);
                    item.index = index;
                }
            },
        });

        return (
            <div ref={(node) => ref(drop(node))} className="image-container">
                <img src={`${serverUrl}/api/assets/imagens/anuncios/${image.url}`} alt="Preview" />
                <button type="button" onClick={() => handleRemoveImage(index)}>Remover</button>
            </div>
        );
    };

    return (
        <Form.Group>
            <Form.Label>Imagens</Form.Label>
            <Form.Control type="file" accept="image/*" multiple onChange={handleImageChange} />
            <div className="image-preview">
                {images.map((img, index) => (
                    <Image key={img.id} image={img} index={index} />
                ))}
            </div>
            <button type="button" onClick={handleSaveOrder}>Salvar Ordem</button>
        </Form.Group>
    );
};

export default ImageUpload;
