import React, { useState } from 'react';
import ChatMessage from './ChatMessage';
import { Form, Button, InputGroup } from 'react-bootstrap';
import './Chat.css';

const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');

  const sendMessage = () => {
    if (input.trim()) {
      setMessages([...messages, { text: input, user: 'Me' }]);
      setInput('');
    }
  };

  return (
    <div className="chat-container">
      <div className="chat-messages">
        {messages.map((msg, index) => (
          <ChatMessage key={index} message={msg} />
        ))}
      </div>
      <InputGroup className="chat-input">
        <Form.Control
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Digite uma mensagem"
        />
        <Button onClick={sendMessage} variant="primary">Enviar</Button>
      </InputGroup>
    </div>
  );
};

export default Chat;
