import api from '../api';

const fornecedorService = {
    async getFornecedores() {
        const response = await api.get('/fornecedores');
        return response.data;
    },
    async addFornecedor(fornecedor) {
        const response = await api.post('/fornecedores', { fornecedor });
        return response.data;
    },
    async updateFornecedor(id, fornecedor) {
        const response = await api.put(`/fornecedores/${id}`, { fornecedor });
        return response.data;
    },
    async deleteFornecedor(id) {
        const response = await api.delete(`/fornecedores/${id}`);
        return response.data;
    }
};

export default fornecedorService;
