import React, { useState } from 'react';
import { Table, Button, FormControl, Modal, Form } from 'react-bootstrap';
import TextareaAutosize from 'react-textarea-autosize';
import DateModal from './components/DateModal'; // Importe o novo componente
import './DynamicTable.css';

function DynamicTable({ columns, data, onEdit, onDelete, categories, showDeleteButton = true, renderEditComponent }) {
  const [editingRowId, setEditingRowId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [rowIdToDelete, setRowIdToDelete] = useState(null);
  const [editedValue, setEditedValue] = useState({});
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [showDateModal, setShowDateModal] = useState(false);
  const [editingDateField, setEditingDateField] = useState(null);
  const [editingRowIdForDate, setEditingRowIdForDate] = useState(null);

  const handleDoubleClick = (id) => {
    setEditingRowId(id);
    const row = data.find(d => d.id === id);
    setEditedValue({ ...row });
  };

  const handleInputChange = (e, field) => {
    const newValue = e.target.value;
    setEditedValue(prevState => ({
      ...prevState,
      [field]: newValue,
    }));
  };

  const handleKeyDown = (e, id) => {
    if (e.key === 'Enter') {
      saveAllChanges(id);
    } else if (e.key === 'Escape') {
      cancelEdit(id);
    }
  };

  const saveAllChanges = (id) => {
    onEdit(id, editedValue);
    setEditingRowId(null);
  };

  const cancelEdit = (id) => {
    setEditingRowId(null);
    const row = data.find(d => d.id === id);
    setEditedValue({ ...row });
  };

  const handleDeleteClick = (id) => {
    setRowIdToDelete(id);
    setShowDeleteModal(true);
  };

  const confirmDelete = () => {
    onDelete(rowIdToDelete);
    setShowDeleteModal(false);
  };

  const cancelDelete = () => {
    setRowIdToDelete(null);
    setShowDeleteModal(false);
  };

  const handleDateClick = (rowId, field, date) => {
    if (editingRowId === rowId && field === 'data') {
      setEditingDateField(field);
      setSelectedDate(date ? new Date(date.split(' ')[0]) : new Date());
      setEditingRowIdForDate(rowId);
      setShowDateModal(true);
    }
  };


  const handleDateChange = (date) => {
    if (isNaN(date.getTime())) {
      return;
    }
    const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} 00:00:00`;
    setEditedValue((prevState) => ({
      ...prevState,
      [editingDateField]: formattedDate,
    }));

    setSelectedDate(date);

    const updatedValues = { ...editedValue, [editingDateField]: formattedDate };
    onEdit(editingRowIdForDate, updatedValues);

    setShowDateModal(false);
  };

  const parseDate = (dateString) => {
    const date = new Date(dateString.split(' ')[0]);
    return isNaN(date.getTime()) ? null : date;
  };

  return (
    <>
      <Table striped bordered hover className="dynamic-table">
        <thead>
          <tr>
            {columns.map((col, index) => (
              <th
                key={index}
                className={`text-center align-middle col-${col.key}`}
                style={{ width: col.width }}
              >
                {col.label}
              </th>
            ))}
            <th className="text-center align-middle col-actions" style={{ width: '15%' }}>Ações</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row) => (
            <tr key={row.id} onDoubleClick={() => handleDoubleClick(row.id)}>
              {columns.map((col, index) => (
                <td key={index} className={`text-center align-middle col-${col.key}`} style={{ width: col.width }}>
                  {editingRowId === row.id && col.editable !== false ? (
                    renderEditComponent
                      ? renderEditComponent(col, editedValue, handleInputChange)
                      : col.type === 'textarea' ? (
                        <TextareaAutosize
                          className="form-control"
                          minRows={3}
                          maxRows={10}
                          value={editedValue[col.key] || ''}
                          onChange={(e) => handleInputChange(e, col.key)}
                          onKeyDown={(e) => handleKeyDown(e, row.id)}
                        />
                      ) : col.type === 'select' ? (
                        <Form.Control
                          as="select"
                          value={editedValue[col.key] || ''}
                          onChange={(e) => handleInputChange(e, col.key)}
                          onKeyDown={(e) => handleKeyDown(e, row.id)}
                          className="form-select"
                        >
                          <option value="">Selecione...</option>
                          {col.options.map((option, idx) => (
                            <option key={idx} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </Form.Control>
                      ) : col.type === 'date' ? (
                        <span className="text-content" onClick={() => handleDateClick(row.id, col.key, row[col.key])}>
                          {row[col.key]}
                        </span>
                      ) : (
                        <FormControl
                          type={col.type || 'text'}
                          value={editedValue[col.key] || ''}
                          onChange={(e) => handleInputChange(e, col.key)}
                          onKeyDown={(e) => handleKeyDown(e, row.id)}
                          autoFocus={index === 0}
                        />
                      )
                  ) : (
                    col.render ? col.render(row[col.key], row) : <span className="text-content" onClick={() => handleDateClick(row.id, col.key, row[col.key])}>{row[col.key]}</span>
                  )}
                </td>
              ))}
              <td className="text-center col-actions" style={{ width: '15%' }}>
                {showDeleteButton && (
                  <Button variant="outline-danger" size="sm" className="me-2" onClick={() => handleDeleteClick(row.id)}>
                    Excluir
                  </Button>
                )}
                {editingRowId !== row.id && (
                  <Button variant="outline-success" size="sm" onClick={() => handleDoubleClick(row.id)}>
                    Editar
                  </Button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal show={showDeleteModal} onHide={cancelDelete}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmação de Exclusão</Modal.Title>
        </Modal.Header>
        <Modal.Body>Tem certeza de que deseja excluir este item?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={cancelDelete}>
            Cancelar
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            Excluir
          </Button>
        </Modal.Footer>
      </Modal>

      <DateModal
        show={showDateModal}
        handleClose={() => setShowDateModal(false)}
        selectedDate={selectedDate}
        handleDateChange={handleDateChange}
      />
    </>
  );
}

export default DynamicTable;
