import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { FaPaperPlane, FaSmile, FaPaperclip, FaMicrophone, FaTrash, FaCheck } from 'react-icons/fa';
import EmojiPicker from 'emoji-picker-react';
import axios from 'axios';

const InputAreaContainer = styled.div`
  display: flex;
  padding: 20px;
  background: white;
  align-items: center;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
`;

const TextInput = styled.input`
  flex-grow: 1;
  padding: 15px;
  font-size: 16px;
  border: 2px solid #ccc;
  border-radius: 20px;
  margin-right: 10px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
`;

const SendButton = styled.button`
  background-color: #4a67a1;
  color: white;
  padding: 15px 20px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;

  &:hover {
    background-color: #3a5380;
  }
`;

const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  color: #4a67a1;
  margin: 0 10px;
  transition: color 0.3s;

  &:hover {
    color: #3a5380;
  }
`;

const EmojiPickerWrapper = styled.div`
  position: absolute;
  bottom: 70px;
  left: 20px;
  z-index: 10;
`;

const AudioPreviewContainer = styled.div`
  display: flex;
  align-items: center;
  background: #f9f9f9;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
`;

const AudioPlayer = styled.audio`
  margin-right: 10px;
`;

function InputArea({ messages, setMessages, sendMessage }) {
  const [input, setInput] = useState('');
  const [recording, setRecording] = useState(false);
  const [emojiPickerOpen, setEmojiPickerOpen] = useState(false);
  const [audioChunks, setAudioChunks] = useState([]);
  const [audioUrl, setAudioUrl] = useState(null);
  const fileInputRef = useRef(null);
  const mediaRecorderRef = useRef(null);

  const handleSend = () => {
    if (input.trim()) {
      const newMessage = { text: input, isUser: true, timestamp: new Date() };
      sendMessage(newMessage);
      setInput('');
    }
  };

  const handleAudioSend = async () => {
    if (!recording) {
      await startRecording();
    } else {
      stopRecording();
    }
  };

  const startRecording = async () => {
    setAudioChunks([]);
    if (!mediaRecorderRef.current) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const mediaRecorder = new MediaRecorder(stream);
        mediaRecorderRef.current = mediaRecorder;

        mediaRecorder.ondataavailable = event => {
          if (event.data.size > 0) {
            setAudioChunks(prev => [...prev, event.data]);
          }
        };

        mediaRecorder.onstop = async () => {
          if (audioChunks.length > 0) {
            const audioBlob = new Blob(audioChunks, { type: 'audio/webm' });
            const audioUrl = URL.createObjectURL(audioBlob);
            setAudioUrl(audioUrl);
          }
          mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
          mediaRecorderRef.current = null;
        };

        mediaRecorder.start();
        setRecording(true);
      } catch (error) {
        console.error("Error accessing media devices.", error);
        return;
      }
    }
  };

  const stopRecording = () => {
    if (recording && mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setRecording(false);
    }
  };

  const handleEmojiClick = (emojiObject) => {
    setInput(input + emojiObject.emoji);
  };

  const handleFileChange = async event => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async () => {
        const base64String = reader.result.split(',')[1];
        const media = {
          data: base64String,
          mimetype: file.type,
          filename: file.name
        };
        const newMessage = { text: `📎 Arquivo enviado: ${file.name}`, media, isUser: true, timestamp: new Date() };
        sendMessage(newMessage);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAudioConfirm = () => {
    const newMessage = { text: ``, audioUrl, isUser: true, timestamp: new Date() };
    sendMessage(newMessage);
    setAudioUrl(null);
  };

  const handleAudioCancel = () => {
    setAudioUrl(null);
    setAudioChunks([]);
  };

  return (
    <>
      <InputAreaContainer>
        <IconButton onClick={() => fileInputRef.current.click()}>
          <FaPaperclip />
        </IconButton>
        <IconButton onClick={() => setEmojiPickerOpen(!emojiPickerOpen)}>
          <FaSmile />
        </IconButton>
        {emojiPickerOpen && (
          <EmojiPickerWrapper>
            <EmojiPicker onEmojiClick={handleEmojiClick} />
          </EmojiPickerWrapper>
        )}
        <TextInput
          type="text"
          placeholder="Digite sua mensagem..."
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={(e) => (e.key === 'Enter' ? handleSend() : null)}
        />
        <IconButton onClick={handleAudioSend}>
          <FaMicrophone color={recording ? 'red' : '#4a67a1'} />
        </IconButton>
        <SendButton onClick={handleSend}>
          <FaPaperPlane />
        </SendButton>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
      </InputAreaContainer>
      {audioUrl && (
        <AudioPreviewContainer>
          <AudioPlayer controls src={audioUrl} />
          <IconButton onClick={handleAudioConfirm}>
            <FaCheck />
          </IconButton>
          <IconButton onClick={handleAudioCancel}>
            <FaTrash />
          </IconButton>
        </AudioPreviewContainer>
      )}
    </>
  );
}

export default InputArea;
