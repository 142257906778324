import api from './api';

export const getSaidasEstoque = async () => {
    const response = await api.get('/estoque/saidas/saidas');
    return response.data;
};

export const createSaidaEstoque = async (saidaData) => {
    const response = await api.post('/estoque/saidas/adicionar', saidaData);
    return response.data;
};

export const updateSaidaEstoque = async (id, saidaData) => {
    const response = await api.put(`/estoque/saidas/atualizar/${id}`, saidaData);
    return response.data;
};

export const deleteSaidaEstoque = async (id) => {
    try {
        const response = await api.delete(`/estoque/saidas/deletar/${id}`);
        return response.data; // ou apenas retornar se for necessário
    } catch (error) {
        throw new Error(`Erro ao deletar saída de estoque ${id}: ${error.message}`);
    }
};
