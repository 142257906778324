import React, { useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import DateModal from '../../../Common/table/components/DateModal'; // Importe o DateModal
import './FormModal.css';

function FormModal({ showModal, handleClose, handleSubmit, products, fornecedores }) {
    const [formData, setFormData] = useState({
        produtoId: '',
        valorCompra: '',
        quantidade: '',
        fornecedor: '',
        frete: '',
        imposto: '',
        data: '' // Adicione o campo de data
    });
    const [showDateModal, setShowDateModal] = useState(false); // Estado para controlar a exibição do DateModal
    const [selectedDate, setSelectedDate] = useState(new Date()); // Estado para a data selecionada

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
        setFormData({ ...formData, data: `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}` });
        setShowDateModal(false);
    };

    const formatCurrencyForDB = (value) => {
        if (typeof value === 'string' && value.trim() !== '') {
            return parseFloat(value.replace(',', '.'));
        } else if (typeof value === 'number') {
            return value;
        }
        return 0;
    };

    const onSubmit = (e) => {
        e.preventDefault();

        const formattedData = {
            ...formData,
            valorCompra: formatCurrencyForDB(formData.valorCompra),
            frete: formatCurrencyForDB(formData.frete),
            imposto: formatCurrencyForDB(formData.imposto)
        };

        handleSubmit(formattedData);

        setFormData({
            produtoId: '',
            valorCompra: '',
            quantidade: '',
            fornecedor: '',
            frete: '',
            imposto: '',
            data: '' // Limpar o campo de data
        });
    };


    return (
        <>
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Adicionar Entrada de Estoque</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={onSubmit}>
                        <Form.Group controlId="produtoId">
                            <Form.Label>Produto</Form.Label>
                            <Form.Control as="select" name="produtoId" value={formData.produtoId} onChange={handleChange} required>
                                <option value="">Selecione o Produto</option>
                                {products.map(product => (
                                    <option key={product.id} value={product.id}>{product.nome}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="valorCompra">
                            <Form.Label>Valor de Compra</Form.Label>
                            <Form.Control type="text" name="valorCompra" value={formData.valorCompra} onChange={handleChange} placeholder="Valor de Compra" required />
                        </Form.Group>
                        <Form.Group controlId="quantidade">
                            <Form.Label>Quantidade</Form.Label>
                            <Form.Control type="number" name="quantidade" value={formData.quantidade} onChange={handleChange} placeholder="Quantidade" required />
                        </Form.Group>
                        <Form.Group controlId="fornecedor">
                            <Form.Label>Fornecedor</Form.Label>
                            <Form.Control as="select" name="fornecedor" value={formData.fornecedor} onChange={handleChange} required>
                                <option value="">Selecione o Fornecedor</option>
                                {fornecedores.map(fornecedor => (
                                    <option key={fornecedor.id} value={fornecedor.fornecedor}>{fornecedor.fornecedor}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="frete">
                            <Form.Label>Frete</Form.Label>
                            <Form.Control type="text" name="frete" value={formData.frete} onChange={handleChange} placeholder="Frete" />
                        </Form.Group>
                        <Form.Group controlId="imposto">
                            <Form.Label>Imposto</Form.Label>
                            <Form.Control type="text" name="imposto" value={formData.imposto} onChange={handleChange} placeholder="Imposto" />
                        </Form.Group>
                        <Form.Group controlId="data">
                            <Form.Label>Data</Form.Label>
                            <Form.Control type="text" name="data" value={formData.data} onClick={() => setShowDateModal(true)} placeholder="Clique para selecionar a data" readOnly required />
                        </Form.Group>
                        <Button variant="primary" type="submit" className="mt-3 w-100">
                            Adicionar Entrada
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>

            <DateModal
                show={showDateModal}
                handleClose={() => setShowDateModal(false)}
                selectedDate={selectedDate}
                handleDateChange={handleDateChange}
            />
        </>
    );
}

export default FormModal;
