import api from './api';

export const getEntradasEstoque = async () => {
    const response = await api.get('/estoque/entradas/entradas');
    return response.data;
};

export const createEntradaEstoque = async (entradaData) => {
    const response = await api.post('/estoque/entradas/adicionar', entradaData);
    return response.data;
};

export const updateEntradaEstoque = async (id, entradaData) => {
    const response = await api.put(`/estoque/entradas/atualizar/${id}`, entradaData);
    return response.data;
};

export const deleteEntradaEstoque = async (id) => {
    try {
        const response = await api.delete(`/estoque/entradas/deletar/${id}`);
        return response.data; // ou apenas retornar se for necessário
    } catch (error) {
        throw new Error(`Erro ao deletar entrada de estoque ${id}: ${error.message}`);
    }
};
