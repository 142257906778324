import api from './api';

export const updateProductImage = async (id, formData, tabela) => {
  try {
    const response = await api.put(`/imagem/${tabela}/${id}/imagem`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  } catch (error) {
    console.error(`Erro ao fazer upload da imagem: ${error}`);
    throw error;
  }
};
