import React, { useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import DateModal from '../../../Common/table/components/DateModal';
import './FormModal.css';

const tiposDeSaida = [
    'Venda de Produtos',
    'Transferência entre Armazéns',
    'Devoluções',
    'Uso Interno',
    'Doações',
    'Perdas',
    'Amostras',
    'Consumo em Produção',
    'Descarte',
    'Troca',
    'Empréstimo',
    'Bônus ou Incentivos'
];

function FormModal({ showModal, handleClose, handleSubmit, products }) {
    const [formData, setFormData] = useState({
        produtoId: '',
        valorVenda: '',
        quantidade: '',
        tipoSaida: '',
        data: ''
    });
    const [showDateModal, setShowDateModal] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
        setFormData({ ...formData, data: `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}` });
        setShowDateModal(false);
    };

    const parseCurrency = (value) => {
        if (typeof value === 'string') {
            return parseFloat(value.replace(/\./g, '').replace(',', '.'));
        }
        return parseFloat(value);
    };

    const formatCurrencyForDB = (value) => {
        const parsedValue = parseCurrency(value);
        return isNaN(parsedValue) ? 0 : parsedValue.toFixed(2);
    };

    const onSubmit = (e) => {
        e.preventDefault();

        const formattedData = {
            ...formData,
            valorVenda: formatCurrencyForDB(formData.valorVenda)
        };

        handleSubmit(formattedData);

        setFormData({
            produtoId: '',
            valorVenda: '',
            quantidade: '',
            tipoSaida: '',
            data: ''
        });
    };

    return (
        <>
            <Modal show={showModal} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Adicionar Saída de Estoque</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={onSubmit}>
                        <Form.Group controlId="produtoId">
                            <Form.Label>Produto</Form.Label>
                            <Form.Control as="select" name="produtoId" value={formData.produtoId} onChange={handleChange} required>
                                <option value="">Selecione o Produto</option>
                                {products.map(product => (
                                    <option key={product.id} value={product.id}>{product.nome}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="valorVenda">
                            <Form.Label>Valor de Venda</Form.Label>
                            <Form.Control type="text" name="valorVenda" value={formData.valorVenda} onChange={handleChange} placeholder="Valor de Venda" required />
                        </Form.Group>
                        <Form.Group controlId="quantidade">
                            <Form.Label>Quantidade</Form.Label>
                            <Form.Control type="number" name="quantidade" value={formData.quantidade} onChange={handleChange} placeholder="Quantidade" required />
                        </Form.Group>
                        <Form.Group controlId="tipoSaida">
                            <Form.Label>Tipo de Saída</Form.Label>
                            <Form.Control as="select" name="tipoSaida" value={formData.tipoSaida} onChange={handleChange} required>
                                <option value="">Selecione o Tipo de Saída</option>
                                {tiposDeSaida.map((tipo, index) => (
                                    <option key={index} value={tipo}>{tipo}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="data">
                            <Form.Label>Data</Form.Label>
                            <Form.Control type="text" name="data" value={formData.data} onClick={() => setShowDateModal(true)} placeholder="Clique para selecionar a data" readOnly required />
                        </Form.Group>
                        <Button variant="primary" type="submit" className="mt-3 w-100">
                            Adicionar Saída
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>

            <DateModal
                show={showDateModal}
                handleClose={() => setShowDateModal(false)}
                selectedDate={selectedDate}
                handleDateChange={handleDateChange}
            />
        </>
    );
}

export default FormModal;
