import React from 'react';
import './styles/Navbar.css';
import logo from '../assets/images/logo-completa-branca.svg';

function Navbar() {
  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <a className="navbar-brand" href="/">
        <img src={logo} alt="Logo SparkFluxe" />
      </a>
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav ms-auto">
          <li className="nav-item">
            <a className="nav-link" href="/"><i className="fas fa-home"></i> Página Inicial</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/produtos-page"><i className="fas fa-box"></i> Produtos</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/financeiro"><i className="fas fa-chart-line"></i> Financeiro</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/chatbot"><i className="fas fa-users"></i> ChatBot</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/aplicativos"><i className="fas fa-shopping-cart"></i> Aplicativos</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/configuracoes"><i className="fas fa-cogs"></i> Configurações</a>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
