import React from 'react';
import './styles/Footer.css';

function Footer() {
  return (
    <footer className="text-center">
      &copy; 2024 SparkFluxe. Todos os direitos reservados.
    </footer>
  );
}

export default Footer;
