import React from 'react';
import ConfigCard from '../../../../Common/cards/ConfigCard';
import CitySettings from './modals/cidades_atuacao/CitySettings';
import FornecedoresSettings from './modals/fornecedores/fornecedoresSettings';

const SettingsPage = () => {
    const cards = [
        {
            title: 'Mensagens Padrão',
            description: 'Editar as mensagens padrões do ChatBOT, permitindo uma melhor personalização.',
            detailComponent: <p>Conteúdo para Mensagens Padrão</p>,
        },
        {
            title: 'Cidades de Atuação',
            description: 'Gerenciar as cidades onde o serviço está disponível.',
            detailComponent: <CitySettings />,
        },
        {
            title: 'Fornecedores',
            description: 'Configurar o WhatsApp para integração com o sistema.',
            detailComponent: <FornecedoresSettings />,
        },
    ];

    return (
        <div>
            <ConfigCard cards={cards} />
        </div>
    );
};

export default SettingsPage;
