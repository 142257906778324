import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PrivateRoute from './PrivateRoute.jsx';
import Login from './pages/Login/Login';
import Home from './pages/Home/Home';
import Produtos from './pages/Produtos/Produtos';
import Aplicativos from './pages/Aplicativos/Aplicativos';
import PostagemOLX from './pages/PostagemOLX/PostagemOLX.jsx';
import Configuracoes from './pages/Configuracoes/Configuracoes';
import Chatbot from './pages/Chatbot/Chatbot';
import ChatgptPage from './pages/Chatgpt/ChatgptPage.jsx';
import SettingsPage from './pages/Configuracoes/cards/configuracoes_do_sistema/SettingsPage.jsx';
import ControleDeEstoqueEntradas from './pages/ControleDeEstoqueEntradas/Controle_de_Estoque_Entradas.jsx';
import ControleDeEstoqueSaidas from './pages/ControleDeEstoqueSaidas/Controle_de_Estoque_Saidas.jsx';
import ControlePage from './pages/ControlePage/controlePage.jsx';
import PainelFinanceiro from './pages/Financeiro/PainelFinanceiro.jsx'

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route element={<PrivateRoute />}>
                    <Route path="/" element={<Home />} />
                    <Route path="/aplicativos" element={<Aplicativos />} />
                    <Route path="/aplicativos/postagem-olx" element={<PostagemOLX />} />
                    <Route path="/aplicativos/chatpgt" element={<ChatgptPage />} />
                    <Route path="/configuracoes" element={<Configuracoes />} />
                    <Route path="/configuracoes/configuracoes_do_sistema" element={<SettingsPage />} />
                    <Route path="/chatbot" element={<Chatbot />} />
                    <Route path="/produtos-page" element={<ControlePage />} />
                    <Route path="/produtos-page/cadastro_produtos" element={<Produtos />} />
                    <Route path="/produtos-page/entradas" element={<ControleDeEstoqueEntradas />} />
                    <Route path="/produtos-page/saidas" element={<ControleDeEstoqueSaidas />} />
                    <Route path="/financeiro" element={<PainelFinanceiro />} />
                </Route>
            </Routes>
        </Router>
    );
}

export default App;
